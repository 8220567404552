import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Slider from "react-slick";
import AnimateHeight from 'react-animate-height';
import { Utilities } from '../../utilities'
import { FormContactUs, Footer } from '../../components'
import './mstyles.scss'

class SolutionMobile extends Component {

  componentDidMount() {
    const product = Utilities.getProductById(this.props.products, this.props.match.params.id)

    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      categories: product.categories,
      title: product.title,
      solutions: product.solutions,
      description: product.description,
      quality: product.quality,
      efficiency: product.efficiency,
      application: product.application,
      techinfo: product.techinfo,
      drawings: product.drawings,
      installation: product.installation,
      images: product.images,
      product
    }, () => { console.log(this.state.title) })

    // console.log("props", this.props, this.props.application)
  }

  state = {
    nav1: null,
    nav2: null,
    openModal: false,
    height: {
      0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0,
      10: 0, 11: 0, 12: 0, 13: 0, 14: 0, 15: 0, 16: 0, 17: 0, 18: 0, 19: 0,
      20: 0, 21: 0, 22: 0, 23: 0
    },

    product: null,
    activeTab: null,
    categories: null,
    title: null,
    solutions: null,
    description: null,
    quality: null,
    efficiency: null,
    application: null,
    techinfo: [],
    images: [],
    drawings: [],
    installation: ''

  }

  toggle = (index) => {
    const { height } = this.state;
    height[index] = height[index] === 0 ? 'auto' : 0
    this.setState({
      height: height,
    });
  };
  onOpenModal = () => {
    this.setState({ openModal: true });
  };
  onCloseModal = () => {
    this.setState({ openModal: false });
  };
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    };
    const techinfoList = this.state.drawings.map((techinfo, index) =>
      <div className='techinfo-item'>
        <a href={techinfo} title=''>{techinfo.slice(techinfo.lastIndexOf('/') + 1)}</a>
      </div>)

    const drawingsList = this.state.drawings.map((drawings, index) =>
      <div className='drawings-item'>
        <a href={drawings} title=''>{drawings.slice(drawings.lastIndexOf('/') + 1)}</a>
      </div>)

    const imagesList = this.state.images.map((images, index) =>
      <div>
        <div className='product-img'><img src={images} alt="" /></div>
      </div>)

    return (
      <div className='solution-m page-bg'>
        <div className='container-m'>
          <div className='content-m'>

            <h2>{this.state.title}</h2>

            <div className='product-gallery-m'>
              <div className='slider-main'>
                <Slider  {...settings}>
                  {imagesList}
                </Slider>
              </div>
            </div>

            <div className="bttn-make-query-m">
              <a href="#form-contact-us" className="bttn">Направи запитване</a>
            </div>

            <div className="tabs-list-m">
              <div>
                <div className='tab-name' style={{ borderBottom: "1px solid #1fecff" }}>Приложение</div>
                <div>{this.state.application}</div>
              </div>
              <div>
                <div className='tab-name'>Описание</div>
                <div>{this.state.description}</div>
              </div>
              <div>
                <div className='tab-name'>Качество</div>
                <div>{this.state.quality}</div>
              </div>
              <div>
                <div className='tab-name' ></div>
                <div>{this.state.efficiency}</div>
              </div>
              <div>
                <div className='tab-name' >Монтаж</div>
                <div>{this.state.installation}</div>
              </div>
              <div className='foldable-tab'>
                <div className='tab-name' onClick={() => this.toggle(5)}>Техническа информация</div>
                <AnimateHeight className='tab-content' duration={500} height={this.state.height[5]}>
                  <div>{techinfoList}</div>
                </AnimateHeight>
              </div>
              <div className='foldable-tab'>
                <div className='tab-name' onClick={() => this.toggle(6)}>Чертежи</div>
                <AnimateHeight className='tab-content' duration={500} height={this.state.height[6]}>
                  <div>{drawingsList}</div>
                </AnimateHeight>
              </div>
            </div>

            <FormContactUs />
          </div>
        </div>
        <Footer />
      </div >
    );
  }
}

const mapStateToProps = state => ({
  products: state.products.data
})

export default connect(mapStateToProps)(withRouter(SolutionMobile));