import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Utilities } from '../../utilities'
import { connect } from 'react-redux'
import Slider from "react-slick";
// import { CSSTransition, SwitchTransition, TransitionGroup } from 'react-transition-group';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { MakeAQueryBttn } from '../../components/MakeAQueryBttn';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './styles.scss'

class Solution extends Component {

  componentDidMount() {
    const product = Utilities.getProductById(this.props.products, this.props.match.params.id)

    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      categories: product.categories,
      title: product.title,
      solutions: product.solutions,
      description: product.description,
      quality: product.quality,
      efficiency: product.efficiency,
      application: product.application,
      techinfo: product.techinfo,
      drawings: product.drawings,
      installation: product.installation,
      images: product.images,
      product
    }, () => { console.log(this.state.title) })


  }
  state = {
    product: null,

    nav1: null,
    nav2: null,
    activeTab: null,
    categories: null,
    title: null,
    solutions: null,
    description: null,
    quality: null,
    efficiency: null,
    application: null,
    techinfo: [],
    images: [],
    drawings: [],
    installation: ''
  }

  render() {

    const techinfoList = this.state.techinfo.map((techinfo, i) =>
      <div key={i} className='techinfo-list'>
        <a href={techinfo} title=''>{techinfo.slice(techinfo.lastIndexOf('/') + 1)}</a>
      </div>)

    const drawingsList = this.state.drawings.map((drawings, j) =>
      <div key={j} className='drawings-list'>
        <a href={drawings} title=''>{drawings.slice(drawings.lastIndexOf('/') + 1)}</a>
      </div>)

    const imagesList = this.state.images.map((images, k) =>
      <div key={k}>
        <div className='product-img' style={{ backgroundImage: "url(" + images + ")" }}><img src="/imgs/blank.gif" alt="" /></div>
      </div>)

    return (
      <div className='solution page-bg'>

        <div className='container'>
          <div className='top-content'>
            <div className='breadcrumbs'>
              <Link to='/solutions/urban' className='link-back'><span className='opacity'>Решения</span></Link>
              {/* <span className='opacity'>Паркинги</span> */}
              <span className='opacity'>{this.state.title}</span>
            </div>
          </div>
          <div className='content'>
            <MakeAQueryBttn />

            <div className='row'>
              <div className='col col-solution-text'>
                <div className="tabs-list">

                  <Tabs>
                    <TabList>
                      <Tab><span tabname="Описание">Приложение</span></Tab>
                      <Tab><span tabname="Монтаж">Монтаж</span></Tab>
                      <Tab><span tabname="Техническа информация">Техническа информация</span></Tab>
                      <Tab><span tabname="Чертежи">Чертежи</span></Tab>
                    </TabList>

                    <div className='scroll'>
                      <PerfectScrollbar>
                        <TabPanel>
                          <div>
                            <div className="box-p">
                              <h3>ОПИСАНИЕ</h3>
                              <p>
                                {this.state.description}
                              </p>
                            </div>
                            <div className="box-p">
                              <h3>ПРИЛОЖЕНИЕ</h3>
                              <p>
                                {this.state.application}
                              </p>
                            </div>
                            <div className="box-p">
                              <h3>КАЧЕСТВО</h3>
                              <p>
                                {this.state.quality}
                              </p>
                            </div>
                            <div className="box-p">
                              <h3>ЕФИКАСНОСТ</h3>
                              <p>
                                {this.state.efficiency}
                              </p>
                            </div>

                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div>{this.state.installation}</div>
                        </TabPanel>
                        <TabPanel>
                          <div>{techinfoList}</div>
                        </TabPanel>
                        <TabPanel>
                          <div>{drawingsList}</div>
                        </TabPanel>
                      </PerfectScrollbar>
                    </div>
                  </Tabs>
                </div>
              </div>
              <div className='col col-solution-gallery'>
                <div className='product-gallery'>
                  <div className='slider-main'><Slider
                    asNavFor={this.state.nav2}
                    ref={slider => (this.slider1 = slider)}
                    arrows={false}
                  >
                    {imagesList}
                  </Slider>
                  </div>
                  <div className='slider-thumbs'><Slider
                    asNavFor={this.state.nav1}
                    ref={slider => (this.slider2 = slider)}
                    slidesToShow={3}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    arrows={true}
                  >
                    {imagesList}
                  </Slider></div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = state => ({
  products: state.products.data
})

export default connect(mapStateToProps)(withRouter(Solution));