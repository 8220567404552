import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReferencesDesktop from './ReferencesDesktop'
import ReferencesMobile from './ReferencesMobile'
import { withGetScreen } from 'react-getscreen'

class References extends Component {
  render() {
    if (this.props.isMobile()) {
      return <ReferencesMobile />;
    } else {
      return <ReferencesDesktop />;
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(References, options))