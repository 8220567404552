import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom'
import { Footer } from '../../components'
import { connect } from 'react-redux'
import './mstyles.scss'

class ReferencesMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            urbanInfrastructure: [
                { activation: 'struma', name: 'АМ "Струма" ЛОТ 3.1', info: 'Спирално навита тръба iD 630, SN 8 с фланец • Спирално навита трба iD 500, SN 4 с вградена ел. муфа • Каломаслоуловител NS 120 l/s. двусекционен', img: '/references/reference-struma-1.jpg' },
                { activation: 'vasil-levski', name: 'Обществен паркинг метростанция „Васил Левски“, гр. София', info: 'Каломаслоуловител NS 6 l/s.', img: '/references/reference-vasil-levski-1.jpg' },
                { activation: 'obzor', name: 'Канализация гр. Обзор', info: 'Спирално навита тръба iD 500, SN 8 HDPE с вградена ел. муфа', img: '/references/reference-obzor-1.jpg' },
                { activation: 'gorna-banya', name: 'Изграждане на канализация на ул. „Н. Хрелков“, кв. Горна Баня, гр. София', info: 'Спирално навита тръба iD 1200, SN 10 HDPE с вградена ел. муфа', img: '/references/reference-gorna-banya-1.jpg' },
                { activation: 'bojurishte', name: 'Индустриална база Божурище', info: 'Резервоар HDPE 82 m3 за противопожарни нужди', img: '/references/reference-bojurishte-1.jpg' },
                { activation: 'gabrovo', name: 'Обход на гр. Габрово и тунел под Шипка', info: 'Резервоар HDPE 50 m3 за противопожарни нужди', img: '/references/reference-gabrovo-1.jpg' },
                { activation: 'krun', name: 'Реконструкция на канализация на гр. Крън', info: 'Спирално навита трба iD 1600, SN 10 PP с резба и термосвиваема лента', img: '/references/reference-krun-1.jpg' },
                { activation: 'boyana', name: 'Многофамилна жилищна кооперация, кв. Бояна, гр. София', info: '', img: '/references/reference-boyana-1.jpg' },
                { activation: 'hemus', name: 'АМ „Хемус“, участък от 78+500 до км 87+800', info: 'Каломаслоуловител HYDROOIL DUO NS 400 l/s., Спирално навити РЕ тръби с електромуфа ( 100 % водоплътност ) iD 500, Ревизионни шахти РЕ – iD1000', img: '/references/reference-hemus-1.jpg' },
                { activation: 'vitinya', name: 'Тунел „Витиня“ - АМ „Хемус“', info: 'Каломаслоуловител HYDROOIL BYPASS NS 10-100 l/s. • Каломаслоуловител HYDROOIL BYPASS NS 20-200 l/s.', img: '/references/reference-vitinya-1.jpg' },
                { activation: 'rehabilitation', name: 'Рехабилитация на пътя между Враца и пещерата „Леденика“', info: 'Каломаслоуловител HYDROOIL BYPASS NS 6-30 l/s. • Каломаслоуловител HYDROOIL BYPASS NS 15-75 l/s.', img: '/references/reference-rehabilitation-1.jpg' },
                { activation: 'portburgas', name: 'Пристанище Бургас', info: 'Тръба спирало навита PEHD с вградена електрозаваряема муфа ID900 SN14/ СТАНДАРТ ISO 9969 • Тръба спирало навита PEHD с вградена електрозаваряема муфа ID700 SN14/ СТАНДАРТ ISO 9969 • Тръба спирало навита PEHD с вградена електрозаваряема муфа ID500 SN14/ СТАНДАРТ ISO 9969 • Каломаслоуловител PEHD ID2000 H-4000, с вградени ревизионни отвори', img: '/references/reference-portburgas-1.jpg' },
            ],
            infrastructure: [],
        }
    }

    componentDidMount() {
        this.setState({ infrastructure: this.state.urbanInfrastructure })
    }

    render() {
        const infrastructureItems = this.state.infrastructure.map((infrastructure, index) =>
            <div className='reference-item' id={index} >
                <NavLink to={'/reference/' + this.state.infrastructure[index].activation}>
                    <span className='reference-img'>
                        <img src={infrastructure.img} alt=''/>
                    </span>
                    <span className='reference-info'>
                        <span className='reference-name'>
                            {infrastructure.name}<br/>
                            {infrastructure.direction}
                        </span>
                        <span className='icon-arrow-right'></span>
                    </span>
                </NavLink>
            </div>)

        return (
            <div className='references-m page-bg'>
                <div className='container-m'>
                    <div className='content-m'>              
                        {infrastructureItems}           
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}
export default connect()(withRouter(ReferencesMobile));