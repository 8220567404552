export const coefficents = [
    {
        diameter: 'DN16',
        materials: [
            {
                name: 'PVC',
                content: {
                    security: [{
                        value: 2.5,
                        content:
                        {
                            pressure: [
                               { name: 'PN20', results: { min: 2, max: null }}
                            ]
                        }
                    }]
                }
            },
            {
                name: 'PE40',
                content: {
                    security: {
                        value: 1.25,
                        content:
                        {
                            pressure: {
                                PN8: { min: 2, max: 2.3 },
                                PN10: { min: 2.1, max: 2.7 }
                            }
                        }
                    }
                }
            },
        ]
    },
    {
        diameter: 'DN16',
        materials: [
            {
                name: 'PVC',
                content: {
                    security: [{
                        value: 2.5,
                        content:
                        {
                            pressure: [
                               { name: 'PN20', results: { min: 2, max: null }}
                            ]
                        }
                    }]
                }
            },
            {
                name: 'PE90',
                content: {
                    security: {
                        value: 2.25,
                        content:
                        {
                            pressure: {
                                PN7: { min: 2, max: 2.3 },
                                PN11: { min: 2.1, max: 2.7 }
                            }
                        }
                    }
                }
            },
        ]
    },
    {
        diameter: 'DN16',
        materials: [
            {
                name: 'PVC',
                content: {
                    security: [{
                        value: 2.5,
                        content:
                        {
                            pressure: [
                               { name: 'PN20', results: { min: 2, max: null }}
                            ]
                        }
                    }]
                }
            },
            {
                name: 'PE90',
                content: {
                    security: {
                        value: 1.25,
                        content:
                        {
                            pressure: {
                                PN8: { min: 2, max: 2.4 },
                                PN10: { min: 2.1, max: 2.7 }
                            }
                        }
                    }
                }
            },
        ]
    },
    {
        diameter: 'DN16',
        materials: [
            {
                name: 'PVC',
                content: {
                    security: [{
                        value: 1.5,
                        content:
                        {
                            pressure: [
                               { name: 'PN50', results: { min: 2, max: null }}
                            ]
                        }
                    }]
                }
            },
            {
                name: 'PE80',
                content: {
                    security: {
                        value: 1.25,
                        content:
                        {
                            pressure: {
                                PN8: { min: 2, max: 2.3 },
                                PN10: { min: 2.1, max: 2.7 }
                            }
                        }
                    }
                }
            },
        ]
    },

]