import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavSide} from '../../components'
import AboutUsSlider from './AboutUsSlider'
import './styles.scss'

class AboutUs extends Component {
  render() {
    return (
      <div className='about-us'>
        <AboutUsSlider />
        <NavSide /> 
      </div >
    )
  }
}
export default connect()(AboutUs);