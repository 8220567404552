import React, { Component } from 'react';
import { NavMainMobileItems } from '../../components'
import './mstyles.scss'

class NavMainMobile extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }
  closeMenu() {
    this.setState({ isOpen: false })
  }
  toggleMenu() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    let toggleNavStyles ='nav-main-mobile-wrap';
    if (this.state.isOpen) {
      toggleNavStyles = 'nav-main-mobile-wrap isOpen';
      document.body.style.overflow = 'hidden';
    } else {
      toggleNavStyles = 'nav-main-mobile-wrap';
      document.body.style.overflow = 'auto';
    }

    return (
      <div className={toggleNavStyles}>
        <div className={ this.state.isOpen ? "burger-icon burger-icon-isOpen" : "burger-icon"} onClick={this.toggleMenu.bind(this)}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div onClick={() => this.closeMenu()}  className='nav-main-toggle'>
          <NavMainMobileItems />
        </div>
      </div>
    );
  }
}

export default NavMainMobile;
