import React, { Component } from 'react'
import { connect } from 'react-redux'
import './styles.scss'

class Socials extends Component {
  render() {
    return (        
        <div className="socials">
          <div className="item"><a href='https://www.linkedin.com/in/hydropro-bulgaria-7612a91b5/' target='_blank' rel="noopener noreferrer"><span className='icon-linkedIn'></span></a></div>
          <div className="item"><a href='https://www.facebook.com/HydroPro-Bulgaria-127749688879611' target='_blank' rel="noopener noreferrer"><span className='icon-facebook'></span></a></div>
        </div>
    );
  }
}

export default connect()(Socials);
