import React from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import { Footer } from '../../components'
import { Utilities } from '../../utilities'

import './mstyles.scss'

class SolutionsItemsMobile extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      products: []
    }
  }
  componentDidMount() {
    const products = Utilities.getProductsByCategory(this.props.products, this.props.match.params.name)

    console.log(this.props.match.params.name)
    this.setState({
      products
    })
  }

  render() {

    const productsList = this.state.products.map((products, i) =>
      <div className="col">
        <NavLink to={'/solution/' + products.id} className='item-link'>
          <div className='img'>
            <img src={products.images[0]} alt='' />
          </div>
          <div className="info">
            <h3 className='solution-list-name-m'>{products.title}</h3>
            {/* {(products.categoriesNames === '') ? 'Няма добавени' : products.categoriesNames} */}
            <p>{products.shortdescription}</p>
          </div>
        </NavLink>
      </div>)

    return (
      <div className='solutions-list solutions-list-m page-bg'>
        <div className='container-m'>
          <div className='content-m'>
            <div className="row row-items">
              {(productsList === '') ? 'Няма добавени продукти за тази категория' : productsList}
            </div>
          </div>
          <Footer />
        </div>
      </div >
    );
  }
}

const mapStateToProps = state => ({
  products: state.products.data
})
export default connect(mapStateToProps)(withRouter(SolutionsItemsMobile));
