import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormContactUs, Footer, GMap } from '../../components'
import './mstyles.scss'

class ContactsMobile extends Component {
  render() {
    return (
      <div className='contacts-m page-bg'>
        <div className="container-m">
          <div className="content-m">
            <div className="address-map"><GMap /></div>
            <div className="address"><span className='icon icon-pin-full'></span>България, гр. София 1138, бул. Цариградско шосе 164</div>
            <div className='row row-email-phone'>
              <div className='col col-phone'>
                <span className='icon icon-phone'></span>
                <span>+359 2/99 280 16</span>
                <span>+359 893 644 135</span>
              </div>
              <div className='col'><span className='icon icon-mail-full'></span>office@hydropro.bg</div>
            </div>
            <div className='main-content'>
              <FormContactUs />
            </div>
          </div>

          <Footer />
        </div>
      </div>
    )
  }
}

export default connect()(ContactsMobile);