import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import './styles.scss'

class NavMain extends Component {
  render() {
    return (
      <div className='nav-main-wrap'>
        <nav className="nav-main">
          <div className='items-l'>
            <NavLink className="item" to='/solutions/urban'><span>Решения</span></NavLink>
            <NavLink className="item" to='/references'><span>Референции</span></NavLink>
          </div>
          <div className='logo'>
            <NavLink to='/'><img src='/hydropro.svg' alt='HydroPro' /></NavLink>
          </div>
          <div className='items-r'>
            <NavLink className="item" to='/about-us'><span>За нас</span></NavLink>
            <NavLink className="item" to='/contacts'><span>Контакти</span></NavLink>
          </div>
        </nav>  
      </div>
    ); 
  }
}

export default connect()(withRouter(NavMain));
