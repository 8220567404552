import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { NavSide } from '../../components'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Cookies from 'js-cookie';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './styles.scss'

class SpecializedMaterialsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactDetails: { name: null, email: null, phone: null, company: null },
      activeItem: '0',
      materialsList: [
        { img: '/imgs/hyropro-specialized-materials-list-3.jpg', title: 'Калкулация линейно отводняване', info: 'Системата дава решение за отвеждане на големи количества вода при оптимални диаметри и капацитет.', link: '/calculation-linear-drainage' },
        { img: '/imgs/hyropro-specialized-materials-list-1.jpg', title: 'Инструмент за хидравлично оразмеряване на канализационни системи - частично запълнен профил', info: 'Оразмеряване на спиралонавити тръби, по търсено водно количество и скорост или диаметър и скорост', link: '/calculation-hydraulic-sizing-tool' },
        { img: '/imgs/hyropro-specialized-materials-list-2.jpg', title: 'Инструмент за изчисление на дебелините на стените на напорни тръбопроводи от PVC и ПЕВП', info: 'Изчисляване на натоварванията, действащи върху спиралонавити тръби (ПЕ). При различно положение на натоварването, може да се пресметне каква да бъде дебелината на стената на тръбата.', link: '/calculation-wall-thickness' },
        // { img: '/imgs/hyropro-specialized-materials-list-4.jpg', title: 'Калкулатор за резервоари', info: 'Очаквайте скоро.', link: '' }
      ]
    };
  }
  componentDidMount() {
    if (this.state.contactDetails.name == null) {
      this.setState(prevState => ({
        contactDetails: {
          ...prevState.contactDetails,
          name: Cookies.get('name'),
          email: Cookies.get('email'),
          phone: Cookies.get('phone'),
          company: Cookies.get('company'),
        }
      }));
    }
  }
  render() {

    //console.log('details', this.state.contactDetails.name, this.state.contactDetails.email, this.state.contactDetails.phone, this.state.contactDetails.company)
    const materialsItems = this.state.materialsList.map((materialsList, index) =>
      <div className={index === 0 ? 'col col-active' : 'col'} id={'item-' + index} onMouseOver={changeActiveItem.bind(this, index)} >
        {/* {index == 2 ?
          <div onClick={() => alert('Очаквайте скоро!')} className='item'>
            <span className='photo'>
              <img src={materialsList.img} alt='' />
            </span>
            <span className='info'>
              <span className='h3'>{materialsList.title}</span>
              <span className='info-p'>
                <p className='p'>{materialsList.info}</p>
              </span>
            </span>
          </div> : */}
        <Link to={materialsList.link} className='item'>
          <span className='photo'>
            <img src={materialsList.img} alt='' />
          </span>
          <span className='info'>
            <span className='h3'>{materialsList.title}</span>
            <span className='p'>
              {materialsList.info}
            </span>
          </span>
        </Link>
        {/* } */}
      </div>
    )

    function changeActiveItem(index) {
      let i = 0;
      let itemsArr = document.getElementsByClassName('col');
      let itemsArrLength = itemsArr.length;
      for (i; i < itemsArrLength; i++) {
        if (i === index) {
          document.getElementById('item-' + i).className = 'col col-active';
        } else {
          document.getElementById('item-' + i).className = 'col';
        }
      }
    }

    return (
      <div className='specialized-materials-list page-bg'>
        <div className='container'>
          <div className='content'>
            <h3>Специализирани материали</h3>
            {/* <h4>Здравей {this.state.contactDetails.name},</h4> */}

            <div className='scroll'>
              <PerfectScrollbar>
                <div className='hidden-x'>
                  <div className='row'>
                    {materialsItems}
                  </div>
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        </div>
        <NavSide />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  contactDetails: state.user.contactDetails
})
export default connect(mapStateToProps)(SpecializedMaterialsList)
