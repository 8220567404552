export const CalculatorTypes = {
  POST_DATA_START: 'calculator/POST_DATA_START',
  POST_DATA_SUCCESS: 'calculator/POST_DATA_SUCCESS',
  POST_DATA_FAILURE: 'calculator/POST_DATA_FAILURE'
}

export const postData = payload => ({
  type: CalculatorTypes.POST_DATA_START,
  payload
})
