import React, { Component } from 'react'
import { connect } from 'react-redux'
import {NavMainDesktop} from '../NavMain'
import { SpecializedMaterialsBttn } from '../../components'
import './styles.scss'

class HeaderDesktop extends Component {
    render() {
        return (  
            <header className="header" id="header">
                <NavMainDesktop />
                <SpecializedMaterialsBttn />
            </header>
        )
    }
}

export default connect()(HeaderDesktop)
