import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { configureStore } from './config/stores'
import Routes from './components/routes/Routes'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.scss'

let store = configureStore()

class App extends Component {
  constructor () {
    super()
    // const INITIAL_STATE = window.innerWidth
    this.state = {
      // desktop: INITIAL_STATE > 900,
      loading: true
    }
  }

  // updateScreen () {
  //   const width = window.innerWidth
  //   if (width > 900 && this.state.desktop === false) {
  //     this.setState({
  //       desktop: true
  //     })
  //     setTimeout(function () {
  //       window.location.reload()
  //     })
  //   } else if (width <= 900 && this.state.desktop === true) {
  //     this.setState({
  //       desktop: false
  //     })
  //     setTimeout(function () {
  //       window.location.reload()
  //     })
  //   }
  // }
  componentDidMount () {
    setTimeout(() =>
      this.setState({ loading: false })
    , 1500)

    // this.updateScreen()
    // window.addEventListener('resize', this.updateScreen.bind(this))
  }

  // componentWillUnmount () {
  //   window.removeEventListener('resize', this.updateScreen.bind(this))
  // }
  render () {
    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    )
  }
}

export default App
