import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormContactUs, NavSide, Tabs, GMap } from '../../components'
import './styles.scss'

class ContactsDesktop extends Component {
  render() {

    return (
      <div className='contacts page-bg'>
        <div className="container">
          <div className="content">

            <Tabs>
              <span className='tab-box'>
                <span className='h2'>
                  <span className='icon icon-mail'></span> Пишете ни
                </span>
                <span className="tab-info">Скрий форма<span className='icon icon-arrow-right'></span></span>
              </span>

              <div className='tab-content'>
                <div className='main-content'>
                  <FormContactUs />
                </div>

                <div className='row row-contacts-info bottom-content'>
                  <div className='col col-info'>
                    <span className='icon icon-phone'></span>+359 2/99 280 16 +359 893 644 135
                  </div>
                  <div className='col col-info'>
                    <span className='icon icon-mail-full'></span>office@hydropro.bg
                  </div>
                </div>
              </div>

              <span className='tab-box'>
                <span className='h2'>
                  <span className='icon icon-pin'></span> Адрес
                </span>
                <span className="tab-info">Виж карта<span className='icon icon-arrow-right'></span></span>
              </span>

              <div className='tab-content'>
                <div className="main-content map-content">
                  <div className="address-map">
                    {/* <a href='https://goo.gl/maps/uB8EF9AiCU2aU9aP7' target='_blank' rel="noopener noreferrer" ><GMap /></a> */}
                    <GMap />
                  </div>
                </div>
                <div className='row-contacts-info bottom-content'>
                  <span className='icon icon-pin-full'></span>България, гр. София 1138, бул. Цариградско шосе 164
                </div>
              </div>
            </Tabs>

          </div>
        </div>
        <NavSide />
      </div>
    )
  }
}

export default connect()(ContactsDesktop);