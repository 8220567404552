import React, { Component } from 'react';
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link } from 'react-router-dom';
import './styles.scss'

class ShrinkBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: '100%'
            },
            isOpen: true,
            shrinkBoxTitle:''
        };
    };

    toggleHeight = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? '100%' : 0
        this.setState({
            height: height,
        });
    };

    componentDidMount() {
        const eShrinkBoxTitle = document.getElementById('shrink-box-title').offsetHeight + 35 + 'px';
        this.setState({ shrinkBoxTitle: eShrinkBoxTitle });
        //console.log(document.getElementById('shrink-box-title').offsetHeight, 'px')
    }

    render() {
        const toggleIsOpen = this.state.isOpen;
        let toggleStyle;
        let icon = <span className='icon icon-arrow-right'></span>

        if (toggleIsOpen) {
            toggleStyle = 'shrink-box isOpen';
        } else {
            toggleStyle = 'shrink-box';
        }

        let time = this.props.time
        let title = this.props.title
        let text = this.props.text
        let link = this.props.link
        let products = this.props.products
        let backbutton = this.props.backbutton

        return (

            <div className='shrink-box' style={{ 'height': this.state.height[0] === 0 ? this.state.shrinkBoxTitle : '80%' }}>
                
                <div className='shrink-box-reference'>
                <Link to={"/references"}> <span  className='icon icon-arrow-left'></span></Link> {backbutton} 
                  
                </div>

                <div className='box'>
                    {/* 
                    to={link} 
                    {toggleStyle}
                     onClick={this.toggle.bind(this)}
                    */}

                    <div className='shrink-box-title' onClick={() => this.toggleHeight(0)}>
                        <h2 id='shrink-box-title'>{title}</h2>
                        <span className='bttn-toggle'>
                            {this.state.height[0] === 0 ? <span className='icon icon-arrow-right'></span> : <span className='icon icon-close'></span>}
                        </span>
                    </div>

                    <AnimateHeight className='shrink-box-content' duration={500} height={this.state.height[0]}>
                        <div className='scroll-box'>
                            <div className="scroll">
                                <PerfectScrollbar>
                                    <div className='text'>{text}</div>

                                    {/* <div className='nonscroll-box'> */}
                                    <div className='products'>
                                        <h3>Продукти</h3>
                                        <div className='list'>
                                            {products}
                                        </div>
                                    </div>
                                    {/* </div> */}
                                </PerfectScrollbar>
                            </div>
                        </div>
                    </AnimateHeight>



                    {/* {this.props.time ?
                        <span className='time space-top'>{time}
                            <span className='icon iconClose icon-close'></span>
                            <span className='icon iconRight icon-arrow-right'></span>
                        </span> : */}

                </div>
            </div>
        )
    }
}

export default connect()(ShrinkBox); 
