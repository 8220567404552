import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Base64 } from 'js-base64';
import Select from 'react-select';
import { coefficents } from './coefficients';
import './calculator.scss'

const optionsOuterDiameter = [
    { value: '0', label: 'DN 12' },
    { value: '1', label: 'DN 16' },
    { value: '9', label: 'DN 20' },
    { value: '21', label: 'DN 25' },
    { value: '37', label: 'DN 32' },
    { value: '60', label: 'DN 40' },
    { value: '88', label: 'DN 50' },
    { value: '120', label: 'DN 63' },
    { value: '152', label: 'DN 75' },
    { value: '184', label: 'DN 90' },
    { value: '216', label: 'DN 110' },
    { value: '250', label: 'DN 125' },
    { value: '284', label: 'DN 140' },
    { value: '318', label: 'DN 160' },
    { value: '352', label: 'DN 180' },
    { value: '386', label: 'DN 200' },
    { value: '420', label: 'DN 225' },
    { value: '453', label: 'DN 250' },
    { value: '486', label: 'DN 280' },
    { value: '519', label: 'DN 315' },
    { value: '558', label: 'DN 355' },
    { value: '597', label: 'DN 400' },
    { value: '635', label: 'DN 450' },
    { value: '673', label: 'DN 500' },
    { value: '708', label: 'DN 560' },
    { value: '738', label: 'DN 630' },
    { value: '768', label: 'DN 710' },
    { value: '794', label: 'DN 800' },
    { value: '820', label: 'DN 900' },
    { value: '841', label: 'DN 1000' },
    { value: '862', label: 'DN 1200' },
    { value: '876', label: 'DN 1400' },
    { value: '886', label: 'DN 1600' },
];
const optionsTubeMaterial = [
    { value: 'PVC', label: 'PVC' },
    { value: 'PE40', label: 'PE40' },
    { value: 'PE63', label: 'PE63' },
    { value: 'PE80', label: 'PE80' },
    { value: 'PE100', label: 'PE100' },
];
const optionsNominalPressure = [
    { value: '8', label: 'PN 8' },
    { value: '10', label: 'PN 10' },
];

const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? '#434141' : 'transparent',
        color: state.isSelected ? '#ffffff' : '#ffffff',
        padding: '5px 10px',
    }),
}

class Calculator extends Component {

    constructor() {
        super();
        this.state = {
            today: new Date(),
            namefrom: '',
            emailfrom: '',
            phonefrom: '',
            comapnyfrom: '',
            to: 'hydroprobg1@gmail.com, team@fidweb.net',
            subject: 'HydroPro Запитване - Изчисление дебелината на стената при напорни тръбопроводи',

            selectOuterDiameter: null,
            selectTubeMaterial: null,
            selectNominalPressure: null,
            selectCoefficent: null,
            isSearchable: false,

            minWallThicknessRecommended: '',
            maxPermissibleWallThickness: '',
            SDR: '',

            loading: false,
        };
    }

    handleSelectOuterDiameter = selectOuterDiameter => {
        this.setState({ selectOuterDiameter });
        console.log(`selectOuterDiameter selected:`, selectOuterDiameter);
    };
    handleSelectTubeMaterial = selectTubeMaterial => {
        this.setState({ selectTubeMaterial });
    };
    handleSelectCoefficent = selectCoefficent => {
        this.setState({ selectCoefficent });
    };
    handleSelectNominalPressure = selectNominalPressure => {
        this.setState({ selectNominalPressure });
        console.log(`selectOuterDiameter selected:`, selectNominalPressure.value);
    };

    // onChange = (e) => {
    //     /* update the state  */
    //     this.setState({ [e.target.name]: e.target.value });
    // }

    onSubmit(e) {
        this.setState({ loading: true });

        e.preventDefault()
        window.fetch('https://mailer.fidweb.net/mail/send/hydropro', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + Base64.encode('hydropro' + ':' + 'P0div3h!!!ot!!T4z!!K4rantinaBEEEEE')
            },
            body: JSON.stringify({

                from: this.state.emailfrom,
                to: this.state.to,
                subject: this.state.subject,
                body: ` Изпратено от: ${this.state.namefrom} \n Email: ${this.state.emailfrom} \n Фирма: ${this.state.company}\n Телефон за връзка: ${this.state.phonefrom} \n\n HydroPro Запитване - Изчисление дебелината на стената при напорни тръбопроводи \n\n Външен диаметър: ${this.state.selectOuterDiameter.value} \n Материал на тръбата: ${this.state.selectTubeMaterial.value} \n Коефициент за сигурност: 2.5 \n Номинално налягане: ${this.state.selectNominalPressure.value} \n\n Минимална препоръчителна дебелина на стената: ${this.state.minWallThicknessRecommended} \n Максимална допустима дебелина на стената ${this.state.maxPermissibleWallThickness} \n SDR: ${this.state.SDR}`,

                "notifyBody": `
            <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml">
            <body>


                    Изпратено от: ${this.state.namefrom}<br/>
                    Мейл: ${this.state.emailfrom}<br/>
                    Телефон за връзка: ${this.state.phonefrom}<br/>
                    Фирма: ${this.state.comapnyfrom}<br/><br/>            
        
                    <h2>HydroPro Запитване - Изчисление дебелината на стената при напорни тръбопроводи</h2>

                    Външен диаметър: ${this.state.selectOuterDiameter.value}<br/>
                    Материал на тръбата: ${this.state.selectTubeMaterial.value}<br/>
                    Коефициент за сигурност: 2.5<br/>
                    Номинално налягане: ${this.state.selectNominalPressure.value}<br/><br/>
        
                    <h3>Резултати от изчислението</h3>
                    Минимална препоръчителна дебелина на стената: ${this.state.minWallThicknessRecommended}<br/>
                    Максимална допустима дебелина на стената ${this.state.maxPermissibleWallThickness}<br/>
                    SDR: ${this.state.SDR}


            </body>
            </html>
    
            `,
                "notifySubject": "HydroPro - Получено запитване"
            })
        }).then(result => {
            alert('Успешно изпратихте вашето съобщение към нас! Ще се свържем с вас при първа възможност.')
            //console.log("RESULTS:", result)
        }).catch(error => { console.log(error) })
            .finally(() => this.setState({ loading: false }))
    }

    render() {
        console.table(coefficents[0].materials[0].content.security[0].value)
        const {
            today, namefrom, emailfrom, phonefrom, comapnyfrom,
            selectOuterDiameter, selectTubeMaterial, selectNominalPressure, selectCoefficent,
            minWallThicknessRecommended, maxPermissibleWallThickness, SDR,
            isSearchable, loading
        } = this.state;

        // <div class=" css-26l3qy-menu">
        //     <div class=" css-4ljt47-MenuList">
        //         <div class=" css-yt9ioa-option" id="react-select-3-option-0" tabindex="-1">DN 12</div>
        //         <div class=" css-9gakcf-option" id="react-select-3-option-1" tabindex="-1">Option 2</div>
        //         <div class=" css-1n7v3ny-option" id="react-select-3-option-2" tabindex="-1">Option 3</div>
        //         <div class=" css-yt9ioa-option" id="react-select-3-option-3" tabindex="-1">Option 4</div>
        //     </div>
        // </div>


        return (
            <div className='calculator-wall-thickness'>
                <div className='calculator'>

                    <div id='s1' className='section'>
                        <h4>
                            Изчисление дебелината на стената при напорни тръбопроводи
                            <small>(съгл. EN 1452-2:1999 и EN 12201-2:2003)</small>
                        </h4>

                        <div className='row'>
                            <div className='col'>
                                <h4>Въведи</h4>

                                <div className='row form-row'>
                                    <div className='col col-1'><label className='label'>Външен диаметър</label></div>
                                    <div className='col col-2'>
                                        <div className='select-box'>
                                            <Select
                                                name='OuterDiameter'
                                                value={selectOuterDiameter}
                                                onChange={this.handleSelectOuterDiameter}
                                                options={coefficents.map((coefficents, i) => {
                                                    return {
                                                        value: i,
                                                        label: coefficents.diameter
                                                    }
                                                })}
                                                isSearchable={isSearchable}
                                                className='react-select'
                                                styles={customSelectStyles}
                                                placeholder='...'
                                            />
                                        </div>
                                    </div>
                                    <div className='col col-3 opacity'>[mm]</div>
                                </div>
                                <div className='row form-row'>
                                    <div className='col col-1'><label className='label'>Материал на тръбата</label></div>
                                    <div className='col col-2'>
                                        <div className='select-box'>
                                            <Select
                                                name='TubeMaterial'
                                                value={selectTubeMaterial}
                                                onChange={this.handleSelectTubeMaterial}
                                                options={this.state.selectOuterDiameter &&
                                                    coefficents[this.state.selectOuterDiameter.value].materials.map((coefficents, i) => {
                                                        return {
                                                            value: i,
                                                            label: coefficents.name
                                                        }
                                                    })}
                                                isSearchable={isSearchable}
                                                className='react-select'
                                                styles={customSelectStyles}
                                                placeholder='...'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row form-row'>
                                    <div className='col col-1'>
                                        2.5
                                    </div>
                                    <div className='col col-2'>
                                        <div className='select-box'>
                                            <Select
                                                name='coefficent'
                                                value={selectCoefficent}
                                                onChange={this.handleSelectCoefficent}
                                                options={this.state.selectTubeMaterial &&
                                                    coefficents[this.state.selectOuterDiameter.value].materials[this.state.selectTubeMaterial.value].content.security.map((coefficents, i) => {
                                                        return {
                                                            value: i,
                                                            label: coefficents.value
                                                        }
                                                    })}
                                                isSearchable={isSearchable}
                                                className='react-select'
                                                styles={customSelectStyles}
                                                placeholder='...'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row form-row'>
                                    <div className='col col-1'><label className='label'>Номинално налягане</label></div>
                                    <div className='col col-2'>
                                        <div className='select-box'>
                                            <Select
                                                name='NominalPressure'
                                                value={selectNominalPressure}
                                                onChange={this.handleSelectNominalPressure}
                                                options={this.state.selectCoefficent &&
                                                    coefficents[this.state.selectOuterDiameter.value].materials[this.state.selectTubeMaterial.value].content.security[this.state.selectCoefficent.value].content.pressure.map((coefficents, i) => {
                                                        return {
                                                            value: i,
                                                            label: coefficents.name
                                                        }
                                                    })}
                                                isSearchable={isSearchable}
                                                className='react-select'
                                                styles={customSelectStyles}
                                                placeholder='...'
                                            />
                                        </div>
                                    </div>
                                    <div className='col col-3 opacity'>
                                        [бара]
                                </div>
                                </div>

                            </div>
                            <div className='col'>

                                <h4>Резултат от изчислението</h4>

                                <div className='row form-row'>
                                    <div className='col col-1'><label className='label'>Минимална препоръчителна дебелина на стената</label></div>
                                    <div className='col col-2 opacity'>${this.state.selectNominalPressure && coefficents[this.state.selectOuterDiameter.value].materials[this.state.selectTubeMaterial.value].content.security[this.state.selectCoefficent.value].content.pressure[this.state.selectNominalPressure.value].results.min}</div>
                                    <div className='col col-3 opacity'>[mm]</div>
                                </div>
                                <div className='row form-row'>
                                    <div className='col col-1'><label className='label'>Максимална допустима дебелина на стената</label></div>
                                    <div className='col col-2 opacity'>${this.state.selectNominalPressure && coefficents[this.state.selectOuterDiameter.value].materials[this.state.selectTubeMaterial.value].content.security[this.state.selectCoefficent.value].content.pressure[this.state.selectNominalPressure.value].results.max}</div>
                                    <div className='col col-3 opacity'>[mm]</div>
                                </div>
                                <div className='row form-row'>
                                    <div className='col col-1'><label className='label'>SDR</label></div>
                                    <div className='col col-2 opacity'> ${this.state.SDR}</div>
                                    <div className='col col-3 opacity'>[-]</div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className='row-bttn'>
                        <button className='bttn'>Направи запитване</button>
                    </div>

                </div>
            </div>
        )
    }
}

export default connect()(Calculator)