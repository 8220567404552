import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { startLoading } from '../../actions'
import { NavSide } from '../../components'
import './styles.scss'

class HomeDesktop extends Component {

  constructor(props) {
    super(props)
    this.state = {
      productsList: [
        { name: 'Пречиствателна станция', img: '/home/prechistvatelna-stancia.png', description: 'HYDROPRO предлага изработена механична конструкция на пречиствателна станция, предназначена за пречистване на битови отпадъчни води от източници като къщи, вили, къмпинги и малки населени места, които не са свързани към местната канализационна система.', link: '', pdf: false },
        { name: 'HYDROTANK', img: '/home/hydrotank.png', description: 'HYDROPRO предлага хоризонтални и вертикално стоящи резервоари, които се изработват от полиетилен или полипропилен.', link: '/solution/tanks', pdf: false },
        { name: 'Помпена станция', img: '/home/pompena-stancia.png', description: 'HYDROPRO помпените станции се използват за транспортиране води от едно ниво на друго ниво. Инсталират се непосредствено преди или след пречиствателната станция.', link: '', pdf: false },
        { name: 'Пейка', img: '/home/bench.png', description: 'Пейките HYDROTEC са изработени от дърво (FSC 100%, необработени) или стомана и се предлагат в различни дизайни', link: '', pdf: false },
        { name: 'Каскадна шахта', img: '/home/kaskadna.png', description: 'При височина на пада от 0,5 м. до 6 м., се използват КАСКАДНИ ШАХТИ.', link: '/solution/shaft-cascading' },
        { name: 'Hydroline', img: '/home/hydroline.png', description: 'HYDROPRO LINE е подходящ за събиране и използване на дъждовни води, като едновременно съчетава отводнител и колектор в едно съоръжение.', link: '/solution/hydrooil-lineardrainer', pdf: false },
        { name: 'Maxi 150', img: '/home/61540000-maxi-150-d400-r00045.png', description: 'Защита на ръбовете и решетка от чугун.', link: '/data/hydrotek-lineardrainer-maxi/HYDROTEC-otvodnitelni-ylei-RESHETKI-SLOT.pdf', pdf: true },
        { name: 'Защита около дърветата', img: '/home/treeprotectors.png', description: 'Системата за защита на дърветата HYDROTEC гарантира ефективна защита на дърветата: защитава от запечатани подове, които влияят на подаването на въздух и вода - предпазва от повреда при сблъскване, която наранява ствола на дървото', link: '/data/hydrotek-centralparts-bars/HYDROTEC-CATALOG-TREE.pdf', pdf: true },
        { name: 'DSRS - Ревизионна', img: '/home/dsrs-s-cap.png', description: 'Дъждоприемните и ревизионни шахти се използват за отвеждане на дъждовни води от пътната мрежа и има вътрешна стълба за безопасна инспекция.', link: '/solution/outflowpoints-revision', pdf: false },
        { name: 'Hydrooil Bypass', img: '/home/hydrooil-bypass.png', description: 'HYDROOIL BYPASS се използва за пречистване на дъждовна и отпадъчна вода, замърсена с петролни продукти, мазнини, масла, пясък, прах, кал и други.', link: '/solution/hydrooil-bypass', pdf: false },
        { name: 'Кошче', img: '/home/bin.png', description: 'Кошчетата HYDROTEC са изработени от стоманена ламарина, горещо поцинкована.', link: '/data/hydrotek-centralparts-container/HYDROTEC-FAIL-1.pdf', pdf: true },
        { name: 'HYDROTANK', img: '/home/hydrotank.png', description: 'HYDROPRO предлага хоризонтални и вертикално стоящи резервоари, които се изработват от полиетилен или полипропилен.', link: '/solution/tanks', pdf: false },
        // { name: 'Шахта', img: '/home/shahta.png', description: 'Колекторни - Дренажни - Дъждоприемни', link: '', pdf: false },
        // { name: 'DSRS - Дъждоприемна', img: '/home/dsrs-outflowpoints-rainwater.png', description: 'Дъждоприемните и ревизионни шахти се използват за отвеждане на дъждовни води от пътната мрежа и има вътрешна стълба за безопасна инспекция.', link: '/solution/outflowpoints-rainwater', pdf: false },
      ],
      prevSelectedId: '',
    }
  }

  // componentDidMount() {
  //   // window.addEventListener("resize", this.productsToggle);
  // }

  startLoading() {
    this.props.dispatch(startLoading())
  }

  productsToggle = (id) => {

    let boxPoint = document.getElementById('box-point-' + id);
    let boxInfo = document.getElementById('box-info-' + id);
    let boxDashed = document.getElementById('box-dashed-' + id);

    boxInfo.className = 'box-info box-info-active';
    boxDashed.className = 'box-dashed-active';

    let screenHeight = document.body.clientHeight;
    let bgHeight = document.getElementById('bg').offsetHeight;
    let pointPosition = boxPoint.offsetTop; // top space to 'bg' parent element
    let boxInfoPosition = boxInfo.getBoundingClientRect().top;

    let boxDashedHeight = boxInfoPosition - (pointPosition - ((bgHeight - screenHeight) / 2)) - 11;
    // let boxDashedHeight = boxInfoPosition - pointPosition - 11;
    //console.log('id = ' + id, '; screenHeight = ' + screenHeight, '; bgHeight = ' + bgHeight, '; pointPosition = ' + pointPosition, '; boxDashedHeight = ' + boxDashedHeight);

    if (id === 3 || id === 5 || id === 7 || id === 9 || id === 10) {
      boxDashed.getElementsByClassName('box-dashed')[0].style.height = boxDashedHeight + 60 + 'px';
    } else {
      boxDashed.getElementsByClassName('box-dashed')[0].style.height = boxDashedHeight + 'px';
    }

    //hide previous selected elements
    let prevId = (this.state.prevSelectedId);
    if (prevId !== '') {
      if (id !== prevId) {
        document.getElementById('box-info-' + prevId).className = 'box-info';
        document.getElementById('box-dashed-' + prevId).className = 'box-lines';

      }
    }
    this.setState({ prevSelectedId: id });
  }

  render() {

    const productsListPoints = this.state.productsList.map((productsList, index) =>
      <div key={index} id={'box-point-' + index}>
        <div className='box-point' onClick={() => this.productsToggle(index)}>
          <span className='point'></span>
        </div>
      </div>)

    const productsListBoxInfo = this.state.productsList.map((productsList, index) =>
      <div key={index} className='box-info' id={'box-info-' + index}>
        <div className='cell-info'>
          <h3>{productsList.name}</h3>
          <p>
            {productsList.description}
            {(productsList.pdf && productsList.link) ?
              <a href={productsList.link} target="_blank" rel="noopener noreferrer">Вижте повече &raquo;</a>
              :
              (productsList.link) ? <Link to={productsList.link}>Прочетете повече &raquo;</Link> : null
            }
          </p>
        </div>
        <div className='cell-img'>
          {(productsList.pdf && productsList.link) ?
            <a href={productsList.link} target="_blank" rel="noopener noreferrer"><img src={productsList.img} alt={productsList.name} /></a>
            :
            (productsList.link) ? <Link to={productsList.link}><img src={productsList.img} alt={productsList.name} /></Link> : <img src={productsList.img} alt={productsList.name} />
          }
        </div>
      </div>)

    const productsListBoxDashed = this.state.productsList.map((productsList, index) =>
      <div key={index} id={'box-dashed-' + index} className='box-lines'>
        <div className='box-dashed'><img src='/home/dot.svg' alt='' /></div>
        {index === 8 ? <div className='box-dashed box-dashed-2'><img src='/home/dot.svg' alt='' /></div> : null}
      </div>)

    return (
      <div className='home' id='products-list'>

        <div className='bg' id='bg'>

          {productsListPoints}
          <div id='box-point-8a'>
            <div className='box-point' onClick={() => this.productsToggle('8')}>
              <span className='point'></span>
            </div>
          </div>

          {productsListBoxDashed}
          <img src='/home/bg@2x.jpg' alt='HYDROPRO' className='desktop bg-img' />
          <img src='/home/bg.jpg' alt='HYDROPRO' className='laptop bg-img' />
        </div>
        {productsListBoxInfo}

        <NavSide />
      </div >
    )
  }
}

export default connect()(HomeDesktop)
