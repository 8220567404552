import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom'
import { Footer } from '../../components'
import Select from 'react-select';
import { connect } from 'react-redux'
import './mstyles.scss'

const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    border: '0',
    background: state.isSelected ? 'transparent' : 'transparent',
    color: state.isSelected ? '#ffffff' : '#ffffff',
  }),
  control: () => ({
    border: 0,
    padding: 0,
    //textAlign: 'center',
    color: '#ffffff',
    background: 'transparent',
    display: 'flex',
  }),
  singleValue: (provided, state) => ({
    opacity: state.isDisabled ? '0.5' : '1',
    color: '#ffffff',
  }),
}

class SolutionsMobile extends Component {

  constructor(props) {
    super(props)
    this.state = {
      value: '',
      selectValue: '',
      selectedOptionsTabs: null,
      isSearchable: false,
      activeTab: null,
      side_multiplier: 0,
      border_id: [{ index: 0 }],

      urbanInfrastructure: [
        { activation: 'urban-infrastructure-parkinglots', categoryName: 'Градска инфраструктура - Паркинги', name: 'Паркинги', info: 'Паркингите обикновено са зони с натоварен трафик. Те са също така зони, изложени на влиянието на атмосферни влияния и високо замърсяване. Хидропро предлагаме голяма гама от продукти, специализирани именно в намаляване и пречистване на замърсените води.', image: '/solutions/Parking.jpg' },
        { activation: 'urban-infrastructure-pedestrian-areas', categoryName: 'Градска инфраструктура - Пешеходни зони', name: 'Пешеходни зони', info: 'Пешеходните зони са едни от най-предпочитание зони за релакс и почивка. Затова ние предлагаме системи за бързо и ефективно отводняване, което в същото време да гарантира оптимален комфорт и безопасност на пешеходците.', image: '/solutions/Crosswalk.jpg' },
        { activation: 'urban-infrastructure-streets', categoryName: 'Градска инфраструктура - Улици', name: 'Улици', info: 'Една от най-отговорните, от проектантска гледна точка, част от градската среда. Нашите продукти са лесни за монтаж и предлагат изключително бързо отводняване, така важно за запазване на високите нива на безопасност.', image: '/solutions/Streets.jpg' },
        { activation: 'urban-infrastructure-parks-and-gardens', categoryName: 'Градска инфраструктура - Паркове и градини', name: 'Паркове и градини', info: 'Ландшафното проектиране е неизменна част от архитектурата и по-конкретно естетическата част на даден проект. Представяме ви решения с немско качество.', image: '/solutions/Park.jpg' }
      ],
      transportInfrastructure: [
        { activation: 'transport-infrastructure-highway', name: 'Магистрали', info: 'Покриването на високите изисквания, залегнали в Европейските стандарти за пътно проектиране, са основната ни цел. Хидропро предлага решения за управление на повърхностните води от основните инфраструктурни зони.', image: '/solutions/Highway.jpg' },
        { activation: 'transport-infrastructure-tunnel', name: 'Тунели', info: 'Тунелите са изключително сложни транспортни съоръжения, нуждаещи се от висока степен безопасност и пожароустойчивост. Нашите продукти осигуряват комфорт както за проектиращите, така и за движещите се по тях.', image: '/solutions/Tunnels.jpg' },
        { activation: 'transport-infrastructure-bridge', name: 'Мостове', info: 'Мостовите съоръжения са изключително трудни и скъпоструващи за поддръжка и ремонт. Продуктите ни намаляват разходите за поддръжка във времето и запазват заложения експлоатационен живот на съоръжението.', image: '/solutions/Bridges.jpg' },
        { activation: 'transport-infrastructure-roads', name: 'Пътища', info: 'Интензивният трафик и бързата промяна в атмосферните условия са предизвикателства за пътните проектанти. Нашите продукти помагат при осигуряването на оптимално отводняване и комфорт по време на движение.', image: '/solutions/Roads.jpg' },
      ],
      commercialAreas: [
        { activation: 'shopping-areas-supermarkets', name: 'Супермаркети', info: 'Посещението на огромен брой клиенти се нуждае и от голям брой паркоместа за правилното протичане на ежедневните задачи. Ние предлагаме иновативни решение за справяне с повърхностните води както от паркинги, така и от самите сгради.', image: '/solutions/Supermarket.jpg' },
        { activation: 'shopping-areas-malls-and-shopping-centers', name: 'Молове и търговски центрове', info: 'Проектирането, изграждането и експлоатацията на моловете и търговските центрове е комплексна задача съчетаваща атрактивна визия, оптимална безопасност и комфорт, както ниски разходи за поддръжка на системите. Продуктите ни са от голяма важност за правилното изпълнение на системите и запазване на ниски разходи за поддръжка.', image: '/solutions/Mall.jpg' },
        { activation: 'shopping-areas-gas-stations-and-car-service', name: 'Бензиностанции и автосервизи', info: 'Тези зони са едни от най-големите производители на замърсявания. Нашите продукти отговарят на най-високите изисквания за опазване на околната среда.', image: '/solutions/Gasstation.jpg' },
      ],
      industrialAndLogicalZones: [
        { activation: 'industrial-and-logistics-areas-dock', name: 'Пристанища', info: 'Зони с високи нива на безопастност и много тежко натоварен трафик. Нашите продукти отговарят на високите изисквания залагани от проектантите в своите проекти.', image: '/solutions/Harbor.jpg' },
        { activation: 'industrial-and-logistics-areas-airport', name: 'Летища', info: 'Летищата са зони с високи нива на безопастност и много тежко натоварен трафик. Нашите продукти отговарят на високите изисквания залагани от проектантите в своите проекти.', image: '/solutions/Airport.jpg' },
      ],
      residentalConstruction: [
        { activation: 'housing-construction-housing-cooperatives', name: 'Жилищни кооперации', info: 'Създаването на благоприятна среда на живот е наша основна цел. Нашите продукти отговарят на най-високите изисквания за опазване на околната среда.', image: '/solutions/ApartmentBuildings.jpg' },
        { activation: 'housing-construction-one-family-houses', name: 'Еднофамилни къщи', info: 'Създаването на правилната атмосфера за създаване на семейство е от основно значение. Даването на пример с опазването на околната среда е нераздела част.', image: '/solutions/Houses.jpg' },
      ],
      infrastructurs: [],

      optionsTabs: [
        { value: 'urban', label: 'Градска инфраструктура' },
        { value: 'transport', label: 'Транспортна инфраструктура' },
        { value: 'shopping', label: 'Търговски зони' },
        { value: 'industrial', label: 'Индустриални и логически зони' },
        { value: 'house', label: 'Жилищно строителство' },
      ],

    }
  }

  handleChangeTabs = selectedOptionsTabs => {
    this.setState({ selectedOptionsTabs, selectValue: selectedOptionsTabs.value }, () => {
      switch (this.state.selectValue) {
        case 'urban':
          this.setState({ infrastructurs: this.state.urbanInfrastructure })
          break
        case 'transport':
          this.setState({ infrastructurs: this.state.transportInfrastructure })
          break
        case 'shopping':
          this.setState({ infrastructurs: this.state.commercialAreas })
          break
        case 'industrial':
          this.setState({ infrastructurs: this.state.industrialAndLogicalZones })
          break
        case 'house':
          this.setState({ infrastructurs: this.state.residentalConstruction })
          break
        default:
          this.setState({ infrastructurs: this.state.urbanInfrastructure })
          break
      }
    });
    // console.log(this.state.selectValue)
  };

  componentDidMount() {
    this.setState({
      infrastructurs: this.state.urbanInfrastructure,
      imageLocation: this.state.urbanInfrastructure['0'].image
    })

  }

  render() {

    const { selectedOptionsTabs } = this.state

    const infrastructursItems = this.state.infrastructurs.map((infrastructurs, index) =>
      <div className='solution-item' id={index} >
        <NavLink to={'/solutions-list/' + this.state.infrastructurs[index].activation}>
          <span className='solution-img'>
            <img src={infrastructurs.image} alt='' />
          </span>
          <span className='solution-info'>
            <span className='solution-name'>
              <span>{infrastructurs.name}</span>
              {infrastructurs.direction}
            </span>
            <span className='icon-arrow-right'></span>
          </span>
        </NavLink>
      </div>)

    return (
      <div className='solutions-m page-bg'>
        <div className='container-m'>
          <div className='content-m'>
            <div className="select-solutions">
              <h3>Решения</h3>
              {/* {tabsLabels} */}
              <Select
                placeholder="Градска инфраструктура"
                onChange={this.handleChangeTabs.bind(this)}
                value={selectedOptionsTabs}
                options={this.state.optionsTabs}
                className="react-select"
                styles={customSelectStyles}
              />
            </div>
            {infrastructursItems}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default connect()(withRouter(SolutionsMobile));