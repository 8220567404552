import React, { Component } from 'react'
import { connect } from 'react-redux'
import './SpecializedMaterialsItems.scss'

class SpecializedMaterialsItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            materialsList: [
                { img: '/imgs/hyropro-specialized-materials-list-3.jpg', title: 'Калкулация линейно отводняване', info: 'Системата дава решение за отвеждане на големи количества вода при оптимални диаметри и капацитет', link: '/calculation-linear-drainage' },
                { img: '/imgs/hyropro-specialized-materials-list-1.jpg', title: 'Инструмент за хидравлично оразмеряване на канализационни системи - частично запълнен профил', info: 'Оразмеряване на спиралонавити тръби, по търсено водно количество и скорост или диаметър и скорост', link: '/calculation-hydraulic-sizing-tool' },
                { img: '/imgs/hyropro-specialized-materials-list-2.jpg', title: 'Инструмент за изчисление на дебелините на стените на напорни тръбопроводи от PVC и ПЕВП', info: 'Изчисляване на натоварванията, действащи върху спиралонавити тръби (ПЕ). При различно положение на натоварването, може да се пресметне каква да бъде дебелината на стената на тръбата', link: '/calculation-wall-thickness' }
            ]
        }
    }
    render() {
        const SpecializedMaterialsItems = this.state.materialsList.map((materialsList, index) =>
            <div className='item' id={'item-' + index} >
                <div className='photo'>
                    <img src={materialsList.img} alt='' />
                </div>
                <div className='info'>
                    <span className='info-name'>
                        {materialsList.title}
                    </span>
                </div>
            </div>
        )

        return (
            <div className='specialized-materials-items'>
                {SpecializedMaterialsItems}
            </div>
        )
    }
}

export default connect()(SpecializedMaterialsItems)
