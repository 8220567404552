import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { NavSide, SpecializedMaterialsBttn } from '../../components'
import { connect } from 'react-redux'

class NavMainMobileItems extends Component {
    render() {
        return (
            <div>
                <div className="nav-main-mobile-items">
                    <div className="item"><NavLink to='/'>Начало</NavLink></div>
                    <div className="item"><NavLink to='/solutions/urban'>Решения</NavLink></div>
                    <div className="item"><NavLink to='/references'>Референции</NavLink></div>
                    <div className="item"><NavLink to='/about-us'>За Нас</NavLink></div>
                    <div className="item"><NavLink to='/contacts'>Контакти</NavLink></div>
                    <div className="item-bttn">
                        <SpecializedMaterialsBttn />
                    </div>
                </div>
                <NavSide />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    contactDetails: state.user.contactDetails
})
export default connect(mapStateToProps)(NavMainMobileItems);
