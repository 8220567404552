import { ofType, ActionsObservable } from "redux-observable";
import { CalculatorTypes } from "../actions";
import { switchMap, catchError } from "rxjs/operators";
import { Api, Alerts } from "@makedonski/socourt-utilities";
import { ajax } from "rxjs/ajax";

export const calculationEpic = (action$, state$) => {
  return action$.pipe(
    ofType(CalculatorTypes.POST_DATA_START),
    switchMap(({ payload }) => {
      return ajax({
        url: "https://hydro-pro-server.herokuapp.com/calculate",
        // url: "http://localhost:5000/calculate",
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      }).pipe(
        switchMap(({ response }) => {
          console.log("response:: ", response);
          return ActionsObservable.create(obs => {
            obs.next({
              type: CalculatorTypes.POST_DATA_SUCCESS,
              payload: response
            });
          });
        }),
        catchError(err => {
          Alerts.error("Грешка", "невалидни данни!");
          return ActionsObservable.create(obs => {
            console.error(err);
            obs.next({ type: CalculatorTypes.POST_DATA_FAILURE, payload: err });
            obs.complete();
          });
        })
      );
    })
  );
};
