import React from 'react'
import { FormContactUs } from '../../components'
import './styles.scss'

class MakeAQueryBttn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      toggleStyle: 'modal-make-a-query isClose'
    }
  }

  toggle = () => {
    if (this.state.isOpen) {
      document.getElementById("make-a-query").className = 'make-a-query';
      document.getElementById("header").style.opacity = '1';
      this.state.toggleStyle = 'modal-make-a-query isClose'
    } else {
      this.state.toggleStyle = 'modal-make-a-query isOpen'
      document.getElementById("make-a-query").className = 'make-a-query overlay';
      document.getElementById("header").style.opacity = '.1';
    }
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <div className="make-a-query" id='make-a-query'>
        <div className="query-bttn">
          {this.state.isOpen ?
            <span className='bttn-close' onClick={this.toggle}>
              <span className='icon iconClose icon-close'></span>
            </span>
            :
            <button className='bttn' onClick={this.toggle}>Направи запитване</button>
          }
        </div>
        <div className={this.state.toggleStyle} id='modal-make-a-query'>
          <div className='form-contact-us-wrap'>
            <FormContactUs />
          </div>
        </div>
      </div>
    )
  }
}
export default MakeAQueryBttn;