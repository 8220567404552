import React, { Component } from 'react'
import { connect } from 'react-redux'
import SpecializedMaterialsListDesktop from './SpecializedMaterialsListDesktop'
import SpecializedMaterialsListMobile from './SpecializedMaterialsListMobile'
import { withGetScreen } from 'react-getscreen'

class SpecializedMaterialsList extends Component {
  render() {
    if (this.props.isMobile()) {
      return <SpecializedMaterialsListMobile />
    } else {
      return <SpecializedMaterialsListDesktop />
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(SpecializedMaterialsList, options))
