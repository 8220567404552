import React, { Component } from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import './styles.scss'
import googleMapStyles from './GMapStyles';

export class GMap extends Component {
  render() {
    console.log(this.props.mapStyle);
    return (
      <div className='gmap'>
        <Map
          google={this.props.google}
          zoom={14}
          initialCenter={{
            lat: 42.6345395,
            lng: 23.4191963
          }}
          styles={this.props.GMapStyles}

          // fullscreenControl={false}
          streetViewControl ={false}
          mapTypeControl ={false}
          zoomControl = {false}
        >
          <Marker
            // onClick={this.onMarkerClick}
            title={'бул. Цариградско шосе - 164, гр.София 1138'}
            name={'HYDROPRO'}
            position={{ lat: 42.6345395, lng: 23.4191963 }}
            icon={{
              url: "/icons/pin-map.svg",
              // anchor: new google.maps.Point(32,32),
              // scaledSize: new google.maps.Size(64,64)
              }}
          // position={markerCenter}
          />
        </Map>
      </div>
    );
  }
}

GMap.defaultProps = googleMapStyles;

export default GoogleApiWrapper({
  apiKey: ('AIzaSyALipb3WN32r90_zyf2wk-vNrEod_JdR5Q')
})(GMap)

// export default connect()(GMap);
