import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withGetScreen } from 'react-getscreen'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

class Header extends Component {
    render() {
        if (this.props.isMobile()) {
          return <HeaderMobile />;
        } else {
          return <HeaderDesktop />;
        }
      }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(Header, options))

