import React, { Component } from 'react';
import { connect } from 'react-redux'
// import { Socials, NavMainMobileItems } from '../../components'
import './styles.scss'

class FooterMobile extends Component {
  render() {
    return (
      <div className='footer-m'>
        {/* <div className='footer-nav-main'>
          <NavMainMobileItems />
        </div> */}
        <div className='row row-socials'>
          <div className="col"><a href='https://www.linkedin.com/in/hydropro-bulgaria-7612a91b5/' target='_blank' rel="noopener noreferrer"><span className='icon-linkedIn'></span></a></div>
          <div className="col"><a href='https://www.facebook.com/pages/category/Brand/Hydropro-BG-Ltd-1929308104055621/' target='_blank' rel="noopener noreferrer"><span className='icon-facebook'></span></a></div>
        </div>
      </div>
    );
  }
}

export default connect()(FooterMobile);
