import React from 'react'
import { connect } from 'react-redux'
import { NavSide } from '../../components'
import { Link, withRouter } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Utilities } from '../../utilities'
import './styles.scss'

class SolutionsListDesktop extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      products: [],
      categoryName: ''
      //categories: [],
      // categoryName: ''
    }
  }

  componentDidMount() {
    const products = Utilities.getProductsByCategory(this.props.products, this.props.match.params.name)
    const category = Utilities.getCategoryName(this.props.categories, this.props.match.params.name)
    console.log(this.props.match.params.name)

    this.setState({
      products,
      categoryName: category.categoryName,

    })

  }

  render() {

    const productsList = this.state.products.map((products, i) =>
      <div className="col">
        <Link to={products.pdf ? products.pdfURL : '/solution/' + products.id } target={products.pdf ? '_blank' : ''} className='item-link'>

          {/* <NavLink to={'/solution/' + products.id} className='item-link'> */}
          {/* to={products.pdf ? '/solution/' + products.id : products.pdfURL  }  target='_blank'  */}

          <span className='img'>
            <img src={products.images[0]} alt='' />
          </span>
          <span className="info">
            <span className="h3">{products.title}</span>
            {/* {(products.categoriesNames === '') ? 'Няма добавени' : products.categoriesNames} */}
            <p>{products.shortdescription}</p>
          </span>
        </Link>
      </div>)

    return (
      <div className='solutions-list page-bg'>
        <div className='container'>
          <div className='content'>

            <div className='top-content'>
              <div className='breadcrumbs'>
                {/* <Link to='/solutions' className='link-back'> {this.state.categoryName}</Link> */}
                {this.state.categoryName.includes('Градска') ? <Link to='/solutions/urban' className='link-back'> {this.state.categoryName}</Link> :
                  this.state.categoryName.includes('Транспортна') ?
                    <Link to='/solutions/transport' className='link-back'> {this.state.categoryName}</Link> :
                    this.state.categoryName.includes('Търговски') ?
                      <Link to='/solutions/shopping' className='link-back'> {this.state.categoryName}</Link> :
                      this.state.categoryName.includes('Индустриални') ?
                        <Link to='/solutions/industrial' className='link-back'> {this.state.categoryName}</Link> :
                        this.state.categoryName.includes('Жилищно') ?
                          <Link to='/solutions/house' className='link-back'> {this.state.categoryName}</Link> : ''
                }
              </div>
            </div>

            <div className='main-content'>
              <div className='scroll'>
                <PerfectScrollbar>
                  <div className="row row-items">

                    {(productsList === '') ? 'Няма добавени продукти' : productsList}

                  </div>
                </PerfectScrollbar>
              </div>
            </div>

          </div>
        </div>
        <NavSide />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.products.data,
  categories: state.products.dataCategories
})

export default connect(mapStateToProps)(withRouter(SolutionsListDesktop));