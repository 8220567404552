import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReferenceDesktop from './ReferenceDesktop'
import ReferenceMobile from './ReferenceMobile'
import { withGetScreen } from 'react-getscreen'

class Reference extends Component {
  render() {
    if (this.props.isMobile()) {
      return <ReferenceMobile  {...this.props}  />;
    } else {
      return <ReferenceDesktop {...this.props} />;
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(Reference, options))