import React, { useState } from "react";
import "./calculator.scss";

export default function Area({ onChange }) {
  const [areaList, setAreaList] = useState([{ L1: "", B11: "", B12: "" }]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...areaList];
    list[index][name] = value;
    setAreaList(list);
    onChange(areaList);
  };

  const handleRemoveClick = index => {
    const list = [...areaList];
    list.splice(index, 1);
    setAreaList(list);
  };

  const handleAddClick = () => {
    setAreaList([...areaList, { L1: "", B11: "", B12: "" }]);
  };

  return (
    <div className="area">
      <h2>Площ на терена за отводняване</h2>
      {areaList.map((area, i) => {
        return (
          <div>
            <div className="row">
              <div className="col">
                <label className="label">Дължина [m]</label>
                <div>
                  <input
                    name="L1"
                    className="input"
                    placeholder="Дължина"
                    value={area.L1}
                    onChange={e => handleInputChange(e, i)}
                  />
                </div>
              </div>
              <div className="col">
                <label className="label">Начална ширина [m]</label>
                <div>
                  <input
                    name="B11"
                    className="input"
                    placeholder="Начална ширина"
                    value={area.B11}
                    onChange={e => handleInputChange(e, i)}
                  />
                </div>
              </div>
              <div className="col">
                <label className="label">Крайна ширина [m]</label>
                <div>
                  <input
                    name="B12"
                    className="input"
                    placeholder="Крайна ширина"
                    value={area.B12}
                    onChange={e => handleInputChange(e, i)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {areaList.length !== 1 && (
                <button className="bttn" onClick={() => handleRemoveClick(i)}>
                  Премахни
                </button>
              )}
              {areaList.length - 1 === i && (
                <button className="add-bttn" onClick={handleAddClick}>
                  +Добави площ
                </button>
              )}
            </div>
          </div>
        );
      })}
      {/* <div style={{ marginTop: 20 }}>{JSON.stringify(areaList)}</div> */}
    </div>
  );
}
