import { CalculatorTypes } from '../actions'

const initialState = {
  data: [],
  graph: []
}

const calculator = (state = initialState, { type, payload }) => {
  switch (type) {
    case CalculatorTypes.POST_DATA_SUCCESS:
      return { ...state, data: payload.results, graph: payload.graph }
    default:
      return state
  }
}

export default calculator
