import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Calculator from './calculator'
import './mstyles.scss'

class CalculationWallThicknessMobile extends Component {

    render() {
        return (
            <div className='calculation-wall-thickness-m'>
                <div className='container-m'>
                    <div className='content-m'>
                        <h3>
                            <Link to='/specialized-materials-list' className='link-back'><span className='icon icon-arrow-left'></span>Специализирани материали</Link>
                            <span>Инструмент за изчисление на дебелините на стените на напорни тръбопроводи от PVC и ПЕВП</span>
                        </h3>
                        <Calculator />
                    </div>
                </div>
            </div>
        )
    }

}

export default connect()(CalculationWallThicknessMobile)