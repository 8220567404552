import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie';
import './styles.scss'

class SpecializedMaterialsBttn extends Component {
    render() {
        return (
            <div className="specialized-materials-bttn">
                <Link to={Cookies.get('name') ? "/specialized-materials-list" : "/specialized-materials"}>
                    {/* <Link to="/specialized-materials"> */}
                    За проектанта <span className='icon icon-arrow-right'></span>
                </Link>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    contactDetails: state.user.contactDetails
})

export default connect(mapStateToProps)(SpecializedMaterialsBttn)
