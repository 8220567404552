import React, { Component } from 'react'
import { connect } from 'react-redux'
import SolutionsDesktop from './SolutionsDesktop'
import SolutionsMobile from './SolutionsMobile'
import { withGetScreen } from 'react-getscreen'

class Solutions extends Component {
  render() {
    if (this.props.isMobile()) {
      return <SolutionsMobile />;
    } else {
      return <SolutionsDesktop />;
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(Solutions, options))