import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Step1, Step2 } from './'
import Cookies from 'js-cookie';
import { addContactDetails } from '../../actions'
import './SpecializedMaterialsForm.scss'
import { withRouter } from "react-router";

class SpecializedMaterialsForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            emailError: "",
            fields: {},
            errors: {},
            currentStep: 1,
            stepOne: '',
            stepTwo: '',
            allInputs: [],
            step1data: [
                {
                    name: '', company: '', email: '', phone: ''
                }
            ],
            message: {
                to: 'hydroprobg1@gmail.com, team@fidweb.net',
                body: ''
            },
            submitting: false,
            error: false,
        }
        this._next = this._next.bind(this)
    }


    componentWillMount() {
        this.setState({ allInputs: [] })
    }
    saveValues(input) {
        let allInputs = this.state.allInputs;
        allInputs.push(input);
        this.setState({ allInputs: allInputs });
    }

    _next() {
        let currentStep = this.state.currentStep
        currentStep = currentStep >= 2 ? 2 : currentStep + 1
        this.setState({
            currentStep: currentStep
        })
    }

    stepData(data) {
        this.setState({ step1data: data })
        // console.log(this.state.step1data)
    }
    sendSMS() {
        // Twilio
        let random_code = Math.floor(1000 + Math.random() * 9000)
        this.setState({ randomCode: random_code })
        // console.log(random_code)
        const url = 'https://api.twilio.com/2010-04-01/Accounts/AC565f13e686cebc1091d7e6c14a53d722/Messages.json';
        const accountSid = 'AC565f13e686cebc1091d7e6c14a53d722';
        const authToken = 'd4bf789010c812698ab30f06c1a4abff';
        const auth = 'Basic ' + new Buffer(accountSid + ':' + authToken).toString('base64');
        const details = {
            To: this.state.step1data[0].phone,
            From: '14843344190',
            // MessagingServiceSid: Config.sms.serviceSid,
            Body: 'Здравейте ' + this.state.step1data[0].name + ', Вашият код за достъп до системата на HYDROPRO е ' + random_code
        };
        const formBody = [];
        for (var property in details) {
            const encodedKey = encodeURIComponent(property);
            const encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + '=' + encodedValue);
        }
        const body = formBody.join('&');
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                Authorization: auth
            },
            body
        };
        return new Promise((resolve, reject) => {
            return fetch(url, options)
                .then((response) => {
                    return resolve(response);
                })
                .then((responseJson) => {
                    return resolve(responseJson);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
    codePass(code) {
        this.setState({ insertedCode: code }, () => {
            console.log(this.state.insertedCode)
        })
    }
    handleSubmit = (event) => {
        event.preventDefault()
        // const { stepOne, stepTwo } = this.state

        // alert(`Данни за регистрацията: \n 
        //   stepOne: ${this.state.step1data[0].name} \n 
        //   stepTwo: ${stepTwo} `)

        // window.location.href = "/specialized-materials-list";
        this.props.addContactDetails({
            ...this.state.step1data
        })
        Cookies.set('name', this.state.step1data[0].name, { expires: 365 });
        Cookies.set('email', this.state.step1data[0].email, { expires: 365 });
        Cookies.set('phone', this.state.step1data[0].phone, { expires: 365 });
        Cookies.set('company', this.state.step1data[0].company, { expires: 365 });
        this.props.history.push('/specialized-materials-list')


        // this.sendSMS()
        //     .catch((err) => {
        //         console.log('Error SMS sender', err);
        //     });

    }


    get nextButton() {
        let currentStep = this.state.currentStep;
        if (currentStep < 2) {
            return (

                <div className='content-footer'>
                    <div className='form-row-bttn'>
                        {this.state.errors.name ? <span className='error'>{this.state.errors.name}</span> : null}
                        {this.state.step1data[0].name.length <= 2 || this.state.step1data[0].company.length <= 2 || this.state.step1data[0].email.length <= 2 || !this.state.step1data[0].email.includes("@") || this.state.step1data[0].phone.length <= 10 ?
                            <button className='bttn' onClick={() => alert("Полетата не са попълнени правилно")}  >Напред <span className='icon icon-arrow-right'></span></button>
                            :
                            <button className='bttn' onClick={() => {
                                this._next()
                                this.sendSMS()
                            }} >Напред <span className='icon icon-arrow-right'></span></button>

                        }
                    </div>
                    <div className='progress-line'><div className='line' style={{ width: '33.3333%' }}></div></div>
                </div>
            )
        } else {
            return (
                <div className='content-footer'>
                    <div className='form-row-bttn'>
                        {this.state.insertedCode !== this.state.randomCode ?
                            <button className='bttn' onClick={this.handleSubmit}  >Напред <span className='icon icon-arrow-right'></span></button>
                            :
                            <button className='bttn' onClick={this.handleSubmit} >Напред <span className='icon icon-arrow-right'></span></button>
                        }
                    </div>
                    <div className='progress-line'><div className='line' style={{ width: '66.6666%' }}></div></div>
                </div>
            )
        }

    }


    render() {
        return (
            <div className="form-materials-contact">

                <Step1
                    currentStep={this.state.currentStep}
                    stepOne={this.state.stepOne}
                    dataPass={this.stepData.bind(this)}
                />
                <Step2
                    currentStep={this.state.currentStep}
                    stepTwo={this.state.stepTwo}
                    phone={this.state.step1data[0].phone}
                    codePass={this.codePass.bind(this)}
                />
                {this.nextButton}
            </div>
        );
    }
}
const mapStateToProps = state => ({

})
const mapDispatchToProps = dispatch => ({
    addContactDetails: payload => dispatch(addContactDetails(payload))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SpecializedMaterialsForm));
