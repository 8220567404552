import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { connect } from 'react-redux'
import { NavSide } from '../../components'
import { CSSTransition, SwitchTransition, } from 'react-transition-group';
import './styles.scss'

class SolutionsDesktop extends Component {
  state = {
    openModal: false,
    imageLocation: '',
    side_multiplier: 0,
    border_id: [{ index: 0 }],
    buttons: [
      { name: 'Градска инфраструктура', activation: 'urban' },
      { name: 'Транспортна инфраструктура', activation: 'transport' },
      { name: 'Търговски зони', activation: 'shopping' },
      { name: 'Индустриални и логистични зони', activation: 'industrial' },
      { name: 'Жилищно строителство', activation: 'house' }
    ],
    activeTab: null,
    urbanInfrastructure: [
      { activation: 'urban-infrastructure-parkinglots', categoryName: 'Градска инфраструктура - Паркинги', name: 'Паркинги', info: 'Паркингите обикновено са зони с натоварен трафик. Те са също така зони, изложени на влиянието на атмосферни влияния и високо замърсяване. Хидропро предлагаме голяма гама от продукти, специализирани именно в намаляване и пречистване на замърсените води.', image: '/solutions/Parking.jpg' },
      { activation: 'urban-infrastructure-pedestrian-areas', categoryName: 'Градска инфраструктура - Пешеходни зони', name: 'Пешеходни зони', info: 'Пешеходните зони са едни от най-предпочитание зони за релакс и почивка. Затова ние предлагаме системи за бързо и ефективно отводняване, което в същото време да гарантира оптимален комфорт и безопасност на пешеходците.', image: '/solutions/Crosswalk.jpg' },
      { activation: 'urban-infrastructure-streets', categoryName: 'Градска инфраструктура - Улици', name: 'Улици', info: 'Една от най-отговорните, от проектантска гледна точка, част от градската среда. Нашите продукти са лесни за монтаж и предлагат изключително бързо отводняване, така важно за запазване на високите нива на безопасност.', image: '/solutions/Streets.jpg' },
      { activation: 'urban-infrastructure-parks-and-gardens', categoryName: 'Градска инфраструктура - Паркове и градини', name: 'Паркове и градини', info: 'Ландшафното проектиране е неизменна част от архитектурата и по-конкретно естетическата част на даден проект. Представяме ви решения с немско качество.', image: '/solutions/Park.jpg' }
    ],
    transportInfrastructure: [
      { activation: 'transport-infrastructure-highway', name: 'Магистрали', info: 'Покриването на високите изисквания, залегнали в Европейските стандарти за пътно проектиране, са основната ни цел. Хидропро предлага решения за управление на повърхностните води от основните инфраструктурни зони.', image: '/solutions/Highway.jpg' },
      { activation: 'transport-infrastructure-tunnel', name: 'Тунели', info: 'Тунелите са изключително сложни транспортни съоръжения, нуждаещи се от висока степен безопасност и пожароустойчивост. Нашите продукти осигуряват комфорт както за проектиращите, така и за движещите се по тях.', image: '/solutions/Tunnels.jpg' },
      { activation: 'transport-infrastructure-bridge', name: 'Мостове', info: 'Мостовите съоръжения са изключително трудни и скъпоструващи за поддръжка и ремонт. Продуктите ни намаляват разходите за поддръжка във времето и запазват заложения експлоатационен живот на съоръжението.', image: '/solutions/Bridges.jpg' },
      { activation: 'transport-infrastructure-roads', name: 'Пътища', info: 'Интензивният трафик и бързата промяна в атмосферните условия са предизвикателства за пътните проектанти. Нашите продукти помагат при осигуряването на оптимално отводняване и комфорт по време на движение.', image: '/solutions/Roads.jpg' },
    ],
    commercialAreas: [
      { activation: 'shopping-areas-supermarkets', name: 'Супермаркети', info: 'Посещението на огромен брой клиенти се нуждае и от голям брой паркоместа за правилното протичане на ежедневните задачи. Ние предлагаме иновативни решение за справяне с повърхностните води както от паркинги, така и от самите сгради.', image: '/solutions/Supermarket.jpg' },
      { activation: 'shopping-areas-malls-and-shopping-centers', name: 'Молове и търговски центрове', info: 'Проектирането, изграждането и експлоатацията на моловете и търговските центрове е комплексна задача съчетаваща атрактивна визия, оптимална безопасност и комфорт, както ниски разходи за поддръжка на системите. Продуктите ни са от голяма важност за правилното изпълнение на системите и запазване на ниски разходи за поддръжка.', image: '/solutions/Mall.jpg' },
      { activation: 'shopping-areas-gas-stations-and-car-service', name: 'Бензиностанции и автосервизи', info: 'Тези зони са едни от най-големите производители на замърсявания. Нашите продукти отговарят на най-високите изисквания за опазване на околната среда.', image: '/solutions/Gasstation.jpg' },
    ],
    industrialAndLogicalZones: [
      { activation: 'industrial-and-logistics-areas-dock', name: 'Пристанища', info: 'Зони с високи нива на безопастност и много тежко натоварен трафик. Нашите продукти отговарят на високите изисквания залагани от проектантите в своите проекти.', image: '/solutions/Harbor.jpg' },
      { activation: 'industrial-and-logistics-areas-airport', name: 'Летища', info: 'Летищата са зони с високи нива на безопастност и много тежко натоварен трафик. Нашите продукти отговарят на високите изисквания залагани от проектантите в своите проекти.', image: '/solutions/Airport.jpg' },
    ],
    residentalConstruction: [
      { activation: 'housing-construction-housing-cooperatives', name: 'Жилищни кооперации', info: 'Създаването на благоприятна среда на живот е наша основна цел. Нашите продукти отговарят на най-високите изисквания за опазване на околната среда.', image: '/solutions/ApartmentBuildings.jpg' },
      { activation: 'housing-construction-one-family-houses', name: 'Еднофамилни къщи', info: 'Създаването на правилната атмосфера за създаване на семейство е от основно значение. Даването на пример с опазването на околната среда е нераздела част.', image: '/solutions/Houses.jpg' },
    ],
    infrastructures: [],
  }

  componentDidMount() {
    switch (this.props.match.params.tab) {
      case 'urban':
        this.setState({ infrastructures: this.state.urbanInfrastructure, imageLocation: this.state.urbanInfrastructure[0].image },
          () => document.getElementById(this.state.buttons[0].name).className = 'active-tab')
        break
      case 'transport':
        this.setState({ infrastructures: this.state.transportInfrastructure, imageLocation: this.state.transportInfrastructure[0].image },
          () => document.getElementById(this.state.buttons[1].name).className = 'active-tab')
        break
      case 'public':
        this.setState({ infrastructures: this.state.publicТransport, imageLocation: this.state.publicTransport[0].image },
          () => document.getElementById(this.state.buttons[2].name).className = 'active-tab')
        break
      case 'shopping':
        this.setState({ infrastructures: this.state.commercialAreas, imageLocation: this.state.commercialAreas[0].image },
          () => document.getElementById(this.state.buttons[2].name).className = 'active-tab')
        break
      case 'industrial':
        this.setState({ infrastructures: this.state.industrialAndLogicalZones, imageLocation: this.state.industrialAndLogicalZones[0].image },
          () => document.getElementById(this.state.buttons[3].name).className = 'active-tab')
        break
      case 'sport':
        this.setState({ infrastructures: this.state.sportFacilities, imageLocation: this.state.sportFacilities[0].image },
          () => document.getElementById(this.state.buttons[4].name).className = 'active-tab')
        break
      case 'house':
        this.setState({ infrastructures: this.state.residentalConstruction, imageLocation: this.state.residentalConstruction[0].image },
          () => document.getElementById(this.state.buttons[4].name).className = 'active-tab')
        break
      default:
        this.setState({ infrastructures: this.state.urbanInfrastructure, imageLocation: this.state.urbanInfrastructure[0].image },
          () => document.getElementById(this.state.buttons[0].name).className = 'active-tab')
        break

    }
  }
  render() {
    // function selectInfrastructures(index) {
    //   this.setState({ side_multiplier: { index }.index - this.state.border_id.index, border_id: { index }, side: 'top' },
    //     // console.log('multiplier: ' + this.state.side_multiplier)
    //   )
    // }
    function imageChange(index) {
      this.setState({ imageLocation: this.state.infrastructures[index].image },
        // selectInfrastructures.bind(this, index)
      )
    }

    function switchMenu(index) {
      while (document.getElementsByClassName('active-tab')[0] !== undefined) { document.getElementsByClassName('active-tab')[0].className = '' }
      document.getElementById(this.state.buttons[index].name).className = 'active-tab';
      switch (this.state.buttons[index].activation) {
        case 'urban':
          this.setState({ infrastructures: this.state.urbanInfrastructure }, imageChange.bind(this, index = 0))
          break
        case 'transport':
          this.setState({ infrastructures: this.state.transportInfrastructure }, imageChange.bind(this, index = 0))
          break
        case 'public':
          this.setState({ infrastructures: this.state.publicТransport }, imageChange.bind(this, index = 0))
          break
        case 'shopping':
          this.setState({ infrastructures: this.state.commercialAreas }, imageChange.bind(this, index = 0))
          break
        case 'industrial':
          this.setState({ infrastructures: this.state.industrialAndLogicalZones }, imageChange.bind(this, index = 0))
          break
        case 'sport':
          this.setState({ infrastructures: this.state.sportFacilities }, imageChange.bind(this, index = 0))
          break
        case 'house':
          this.setState({ infrastructures: this.state.residentalConstruction }, imageChange.bind(this, index = 0))
          break
        default:
          this.setState({ infrastructures: this.state.urbanInfrastructure }, imageChange.bind(this, index = 0))
          break
      }
    }
    // const Border = () => <div className="animated-border" style={{ top: "calc(" + this.state.side_multiplier + "*-100px)" }} />;

    const infrastructuresItems = this.state.infrastructures.map((infrastructures, index) =>
      <div className='solution-item' id={index} onClick={this.state.onOpenTab} onMouseOver={imageChange.bind(this, index)}>
        <NavLink to={'/solutions-list/' + this.state.infrastructures[index].activation}>
          <div className='row'>
            <div className='col col-solution-name' onClick={this.onOpenTab} >
              <span className='col-name'>{infrastructures.name}</span>
              <span className='col-info'>{infrastructures.info}</span>
            </div>
            <div className='col col-arrow'><span className='icon-arrow-right'></span></div>
          </div>
        </NavLink>
      </div>)

    const tabsLabels = this.state.buttons.map((buttons, index) =>
      <li id={this.state.buttons[index].name} onClick={switchMenu.bind(this, index)}>
        <span tabname={buttons.name}>{buttons.name}</span>
      </li>)

    return (
      <div className='solutions page-bg'>
        <div className='scroll'>
          <PerfectScrollbar>
            <div className="row">
              <div className="col">
                <div className='container'>
                  <div className='content solutions-items-list'>
                    <div className="tabs-list" >
                      <ul>{tabsLabels}</ul>
                    </div>
                    <div className='tabs-content'>
                      <div className='row'>
                        <div className='col col-solution-img'>
                          <div className='solution-img'>
                            <SwitchTransition mode={"in-out"}>
                              <CSSTransition classNames="fade" timeout={200} key={this.state.imageLocation}>
                                <img src={this.state.imageLocation} alt="" />
                              </CSSTransition>
                            </SwitchTransition>
                          </div>
                        </div>
                        <div className='col col-solutions-items-list'>
                          <div className='scroll'>
                            <PerfectScrollbar>
                              <div className='solutions-items'>
                                {infrastructuresItems}
                              </div>
                            </PerfectScrollbar>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </PerfectScrollbar>
        </div>

        <NavSide />
      </div >
    );
  }
}

export default connect()(withRouter(SolutionsDesktop));