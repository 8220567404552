import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import Slider from "react-slick";

class AboutUsSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }
  render() {
    const settings = {
      dots: true,
      arrows: false,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div className='about-us-slider'>
        <Slider {...settings}>
          <div>
            <div className='container-full page-bg slide-aboutus' style={{ "backgroundImage": "url('/imgs/hydropro-aboutus.jpg')" }}>
              <div className='container'>
                <div className='content content-box-2'>
                  <div className='scroll'>
                    <PerfectScrollbar>
                      <h2>За Компанията</h2>
                      <ul className='list-style'>
                        <li><b>„ХИДРОПРО БГ“</b> ООД е на пазара от 2017г. като търговски представител на производствената българска компания<br/>
                        <b>„ХИДРОСЪОРЪЖЕНИЯ“</b>. Заедно успяваме да постигнем здравословна бизнес среда, целяща дълготрайно развитие и налагане на европейски стандарт на работа.</li>
                        <li>Хидропро прелага специализирани съоръжения за сепариране, отводняване и пречистване на околната среда. Доброто ни име на пазара се дължи на установените контакти и добри взаймоотношения с водещи специалисти, с които заедно разработваме качествени, ефективни и иновативни продукти.</li>
                        <li>Освен това ние сме активен посредник между участниците в строителния процес, осъществявайки съществена връзка между производство, проектиране и строителство. Това ни дава възможност да дадем най-ефективното решение на клиентите ни.</li>
                        <li>От началото на 2019г. „ХИДРОПРО БГ“ е търговски представител за България и на немската „HYDROTEC“.</li>
                        <li>„HYDROTEC“ е водещ производител на смонивелиращи се капаци и решетки, отводнителни улей от фазербетон.</li>
                        <li>От друга страна „HYDROTEC“ е представител на „ХИДРОПРО БГ“ за Европа и света.</li>
                      </ul>
                    </PerfectScrollbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='container-full page-bg slide-partners' style={{ "backgroundImage": "url('/imgs/hydropro-ourpartners.jpg')" }}>
              <div className='container'>
                <div className='content content-box-2'>
                  <div className='scroll'>
                    <PerfectScrollbar>
                      <h2>Нашите партньори</h2>
                      <div className="row partners">
                        <div className='col'><img src="/imgs/partners-hydropro-facilities.png" alt="ХидроПро Съоражения" /></div>
                        <div className='col'><img src="/imgs/partners-hydrotec.png" alt="Хидротеч" /></div>
                      </div>
                    </PerfectScrollbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
export default AboutUsSlider