
export const Utilities = {

  getCategoryName (categories, id) {
    const index = categories.findIndex(p => {
      return p.id === id
    })
    if (index !== -1) {
      return categories[index]
    }
    return null
  },

  getProductsByCategory (products, category) {
    const filteredProducts = products.filter(p => {
      return p.categories.indexOf(category) !== -1
    })
    return filteredProducts
  },

  getProductById (products, id) {
    const index = products.findIndex(p => {
      return p.id === id
    })
    if (index !== -1) {
      return products[index]
    }
    return null
  }
}
