import React, { Component } from 'react'
import { connect } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';

import './SpecializedMaterialsGallery.scss'

class SpecializedMaterialsGallery extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeItem: '0',
      materialsList: [
        { img: '/imgs/hyropro-specialized-materials-list-3.jpg', title: 'Калкулация линейно отводняване', info: 'Системата дава решение за отвеждане на големи количества вода при оптимални диаметри и капацитет', link: '/calculation-linear-drainage' },
        { img: '/imgs/hyropro-specialized-materials-list-1.jpg', title: 'Инструмент за хидравлично оразмеряване на канализационни системи - частично запълнен профил', info: 'Оразмеряване на спиралонавити тръби, по търсено водно количество и скорост или диаметър и скорост', link: '/calculation-hydraulic-sizing-tool' },
        { img: '/imgs/hyropro-specialized-materials-list-2.jpg', title: 'Инструмент за изчисление на дебелините на стените на напорни тръбопроводи от PVC и ПЕВП', info: 'Изчисляване на натоварванията, действащи върху спиралонавити тръби (ПЕ). При различно положение на натоварването, може да се пресметне каква да бъде дебелината на стената на тръбата', link: '/calculation-wall-thickness' }
      ]
    };
  }

  render() {

    const materialsPhotos = this.state.materialsList.map((materialsList, index) =>
      <div className={index === 0 ? 'thumb thumb-active' : 'thumb'} id={'item-photo-' + index} onClick={changeActiveItem.bind(this, index)}>
        <div className='photo'>
          <img src={materialsList.img} alt='' />
        </div>
      </div>
    )

    const materialsInfo = this.state.materialsList.map((materialsList, index) =>
      <div className={index === 0 ? 'show' : 'hide'} id={'item-info-' + index} >
        <h3>{materialsList.title}</h3>
        <p>{materialsList.info}</p>
      </div>
    )

    function changeActiveItem(index) {
      let i = 0;
      let topStep = 0;
      let keepStep = topStep;

      let itemsArr = document.getElementsByClassName('thumb');
      let itemsArrLength = itemsArr.length;
      for (i; i < itemsArrLength; i++) {
        if (i === index) {
          document.getElementById('item-photo-' + i).className = 'thumb thumb-active';
          document.getElementById('item-photo-' + i).style.top = 0;
          document.getElementById('item-info-' + i).style.display = 'block';
        } else {
          document.getElementById('item-photo-' + i).className = 'thumb';
          document.getElementById('item-photo-' + i).style.top = topStep + '%';
          keepStep = topStep + 50;
          topStep = keepStep;
          document.getElementById('item-info-' + i).style.display = 'none';
        }
      }

      // document.getElementById('img-bg').style.backgroundImage = `url(${this.state.galleryItems[index].img})`;
      // this.setState({ img: this.state.galleryItems[index].img }, selectImg.bind(this, index))
    }

    return (
      <div className='specialized-materials-gallery'>
        <div className='photos'>
          {materialsPhotos}
        </div>
        <div className='info'>
          <div className='photo-info'>
            <div className='scrollbar-container'>
              <div className='scroll'>
                <PerfectScrollbar>
                  {materialsInfo}
                </PerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(SpecializedMaterialsGallery)
