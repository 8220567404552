import { combineReducers } from 'redux'
// import { createNavigationReducer } from 'react-navigation-redux-helpers'
import { routerReducer } from 'react-router-redux'

import starter from './starter'
import user from './user'
import products from './products'
import calculator from './calculator'

export default combineReducers({
  starter,
  products,
  routerReducer,
  user,
  calculator
})
