import React, { Component } from 'react';
import { connect } from 'react-redux'
import './mstyles.scss'

class ShrinkBoxMobile extends Component {
    render() {
        let title = this.props.title
        let text = this.props.text
        let products = this.props.products
        return (
            <div className="shrink-box-m">
                <h3>{title}</h3>
                <div className='shrink-box-content'>
                    {text}
                </div>
                <div className='products'>
                    <h4>Продукти</h4>
                    <div className='list'>
                        {products}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(ShrinkBoxMobile); 
