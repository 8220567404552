import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { NavSide } from '../../components'
import Calculator from './calculator'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';

import './styles.scss'

class CalculationLinearDrainage extends Component {

    render() {
        return (
            <div className='calculation-liner-drainage page-bg'>
                <div className='container'>

                    <div className='content'>

                        <div className='top-content'>
                            <div className='breadcrumbs'>
                                <Link to='/specialized-materials-list' className='link-back'><span className='opacity'>Специализирани материали </span></Link>
                                <span className='opacity'>Калкулация линейно отводняване</span>
                            </div>
                        </div>

                        <div className='main-content'>

                            <div className='scroll'>
                                <PerfectScrollbar>

                                    <h3>
                                        Калкулация линейно отводняване
                                        <small>(Попълнете данните, за да Ви дадем най-подходящите предложения)</small>
                                    </h3>

                                    <Calculator/>

                                </PerfectScrollbar>
                            </div>
                        </div>

                    </div>
                </div>

                <NavSide />
            </div>
        )
    }
}

export default connect()(CalculationLinearDrainage)