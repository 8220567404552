import React, { Component } from "react";
import { connect } from "react-redux";
import { Base64 } from "js-base64";
import { postData } from "../../actions";
import Area from "./area.js";
import CreatableSelect from 'react-select/creatable';
import { XYPlot, XAxis, YAxis, HorizontalGridLines, AreaSeries, LineSeries } from 'react-vis';
import { Page, Text, View, Document, StyleSheet, Font, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import "./calculator.scss";

class Calculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      today: new Date(),
      to: "",
      areas: [],
      subject: "HydroPro - Продукти",
      emailfrom: "", // tova e vid obekt prosto ne izprashta email ako emailfrom ne e dobaven vuv formata pri returna kudeto e inputa
      loading: false,
      slope: "",
      roughness: 0.0135,
      L1: 5,
      qlsha: "",
      zeroone: "",
      area1L1: "",
      area1B11: "",
      area1B12: "",
      area2L2: "",
      area2B21: "",
      area2B22: "",
      area3L3: "",
      area3B31: "",
      area3B32: "",
      area4L4: "",
      area4B41: "",
      area4B42: "",
      lastmessage: "",
      size51: "",
      size52: "",
      size53: "",
      size54: "",
      size55: "",
      size56: "",
      size57: "",
      size58: "",
      size59: "",
      area: [{ L1: "", B11: "", B12: "" }],
      diameters: [200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100],
      result: '',
      chunked: []
    };

  }
  get calculate() {
    if (
      this.state.slope.trim() !== "" &&
      this.state.roughness.trim() !== "" &&
      this.state.L1.trim() !== "" &&
      this.state.qlsha.trim() !== "" &&
      this.state.zeroone.trim() !== "" &&
      ((this.state.area1L1.trim() !== "" &&
        this.state.area1B11.trim() !== "" &&
        this.state.area1B12.trim() !== "") ||
        (this.state.area2L2.trim() !== "" &&
          this.state.area2B21.trim() !== "" &&
          this.state.area2B22.trim() !== "") ||
        this.state.area3L3.trim() !== "" ||
        this.state.area3B31.trim() !== "" ||
        this.state.area3B32.trim() !== "" ||
        this.state.area4L4.trim() !== "" ||
        this.state.area4B41.trim() !== "" ||
        this.state.area4B42.trim() !== "") &&
      (this.state.size51.trim() !== "" ||
        this.state.size52.trim() !== "" ||
        this.state.size53.trim() !== "" ||
        this.state.size54.trim() !== "" ||
        this.state.size55.trim() !== "" ||
        this.state.size56.trim() !== "" ||
        this.state.size57.trim() !== "" ||
        this.state.size58.trim() !== "" ||
        this.state.size59.trim() !== "")
    ) {
      return;
    } else {
      return "";
    }
  }
  get lastMessage() {
    if (
      this.state.slope.trim() !== "" ||
      this.state.roughness.trim() !== "" ||
      this.state.L1.trim() !== "" ||
      this.state.qlsha.trim() !== "" ||
      this.state.zeroone.trim() !== "" ||
      this.state.area1L1.trim() !== "" ||
      this.state.area1B11.trim() !== "" ||
      this.state.area1B12.trim() !== "" ||
      this.state.area2L2.trim() !== "" ||
      this.state.area2B21.trim() !== "" ||
      this.state.area2B22.trim() !== "" ||
      this.state.area3L3.trim() !== "" ||
      this.state.area3B31.trim() !== "" ||
      this.state.area3B32.trim() !== "" ||
      this.state.area4L4.trim() !== "" ||
      this.state.area4B41.trim() !== "" ||
      this.state.area4B42.trim() !== "" ||
      this.state.size51.trim() !== "" ||
      this.state.size52.trim() !== "" ||
      this.state.size53.trim() !== "" ||
      this.state.size54.trim() !== "" ||
      this.state.size55.trim() !== "" ||
      this.state.size56.trim() !== "" ||
      this.state.size57.trim() !== "" ||
      this.state.size58.trim() !== "" ||
      this.state.size59.trim() !== ""
    ) {
      return (
        <div className="col">
          <h4>Последно съобщение</h4>
          <div className="row">
            <div className="col">
              <div>
                <textarea
                  type="text"
                  name="lastmessage"
                  id="lastmessage"
                  className="input"
                  placeholder="Последно съобщение"
                  value={this.state.lastmessage}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
    }
  }
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onCheck = e => {
    const options = this.state.diameters;
    let index;
    if (e.target.checked) {
      options.push(+e.target.value);
    } else {
      index = options.indexOf(+e.target.value);
      options.splice(index, 1);
    }
    this.setState({ options: options }, () => console.log(this.state.options));
  };
  onSubmit(e) {
    this.setState({ loading: true });

    e.preventDefault();
    window
      .fetch("https://mailer.fidweb.net/mail/send/hydropro", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Basic " +
            Base64.encode(
              "hydropro" + ":" + "P0div3h!!!ot!!T4z!!K4rantinaBEEEEE"
            )
        },
        body: JSON.stringify({
          from: this.state.emailfrom,
          to: this.state.to,
          subject: this.state.subject,
          body: `Име: ${this.props.contactDetails[0].name} \n  Телефон: ${this.props.contactDetails[0].phone} \n Фирма: ${this.props.contactDetails[0].company} \n Email: ${this.props.contactDetails[0].email} \n Вид Обект: ${this.state.emailfrom} \n Наклон: ${this.state.slope} \n Грапавина: ${this.state.roughness} \n L1: ${this.state.L1} \n q[l/s/ha] ${this.state.qlsha} \n [0-1]: ${this.state.zeroone} \n Площ-1-L1: ${this.state.area1L1} \n Площ-1-B1.1: ${this.state.area1B11} \n Площ-1-B1.2: ${this.state.area1B12} \n Площ-2-B2.1: ${this.state.area2B21} \n Площ-1-B1.2: ${this.state.area1B12} \n Площ-1-B1.2: ${this.state.area2B21} \n Площ-2-L2: ${this.state.area2L2} \n Площ-2-B2.2: ${this.state.area2B22} \n Площ-3-L3: ${this.state.area3L3} \n Площ-3-B3.1: ${this.state.area3B31} \n Площ-3-B3.2: ${this.state.area3B32} \n Площ-4-L4: ${this.state.area4L4} \n Площ-4-B4.1: ${this.state.area4B41} \n Площ-4-B4.2: ${this.state.area4B42} \n 5_1: ${this.state.size51} \n 5_2: ${this.state.size52} \n 5_3: ${this.state.size53} \n 5_4: ${this.state.size54} \n 5_5: ${this.state.size55} \n 5_6: ${this.state.size56} \n 5_7: ${this.state.size57} \n 5_8: ${this.state.size58} \n 5_9: ${this.state.size59} \n Последно Съобщение: ${this.state.lastmessage} \n `,
          notifyBody: `
            <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml">
            <head>
              <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <title>HydroPro</title>
              <style type="text/css">
                * {font-family: Georgia,Times,Times New Roman,serif;}
                #outlook a {padding: 0;}
                body {width: 100% !important; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; margin: 0; padding: 0; }
                img {outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;}
                a img {border: none; }
                h1, h2, h3, h4, h5, h6,
                h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
                h1 a:active, h2 a:active, h3 a:active, h4 a:active, h5 a:active, h6 a:active,
                h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited {font-family: Georgia,Times,Times New Roman,serif; color: #201E18;}
                a {outline: none; text-decoration: none; color: #DFC475; }
                @media only screen and (max-width:600px) {
                  *[class].full {width: 100% !important; overflow: hidden !important;}
                  *[class].cen {margin: 0 auto !important; text-align: center !important;}
                  *[class].img-full {display: block !important; width: 100% !important; height: auto !important;}
                }
              </style>
            </head>
            <body style="margin:0;padding:0;background-color:#ffffff;font-family:Georgia,Times,Times New Roman,serif;font-size:16px;">
              <table width="100%" cellpadding="0" cellspacing="0" border="0" bgcolor="#ffffff">
                <tr>
                  <td valign="top" bgcolor="#ffffff" align="center" width="100%">
                    <table cellpadding="0" cellspacing="0" border="0" align="center" valign="top" width="600" class="full">
                      <tr>
                        <td valign="top">

                            <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="20" width="1" border="0" style="display:block;" /></td></tr></table>

                            <table cellpadding="0" cellspacing="0" border="0" width="100%">
                              <tr>
                                <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                                <td style="text-align:left;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">Контакти</td>
                                <td style="text-align:right;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">дата ${this.state.today}г.</td>
                                <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                              </tr>
                            </table>

                            <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="10" width="1" border="0" style="display:block;" /></td></tr></table>

                            <table cellpadding="0" cellspacing="0" border="0" width="100%">
                              <tr><td align="center"><a href="/" target="_blank" style="text-decoration:none;font-family:Georgia,Times,Times New Roman,serif;"><img src="https://hydropro.bg/email/hydropro-header.jpg" alt="HydroPro" border="0" class="img-full" /></a></td></tr>
                            </table>

                            <table cellpadding="0" cellspacing="0" border="0" width="100%">
                              <tr>
                                <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                                <td style="text-align: left">

                                <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="45" width="1" border="0" style="display:block;" /></td></tr></table>

                                  <h1 style="margin:0;padding:0;text-align:center;font-size:34px;line-height:24px;color:#201E18;font-weight:normal;font-family:Georgia,Times,Times New Roman,serif">Свързахте се с HydroPro</h1>

                                  <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="25" width="1" border="0" style="display:block;" /></td></tr></table>

                                  <p style="font-size:18px;line-height:24px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif">Вие изпратихте запитване към HydroPro:</p>

                                  <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                    <tr>
                                        <td><img src="https://hydropro.bg/email/pix.gif" width="20" height="1" /></td>
                                        <td align="left">
                                            <p style="font-size:18px;line-height:24px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                              Изпратено от:<br/>
                                              Вид Обект: ${this.state.emailfrom}<br/>
                                              Наклон: ${this.state.slope}<br/>
                                              Грапавина: ${this.state.roughness}<br/>
                                              L1: ${this.state.L1}<br/>
                                              q[l/s/ha]: ${this.state.qlsha} <br/>
                                              [0-1]: ${this.state.zeroone} <br/>
                                              Площ-1-L1: ${this.state.area1L1} <br/>
                                              Площ-1-B1.1: ${this.state.area1B11} <br/>
                                              Площ-1-B1.2: ${this.state.area1B12} <br/>
                                              Площ-2-L2: ${this.state.area2L2} <br/>
                                              Площ-2-B2.1: ${this.state.area2B21} <br/>
                                              Площ-2-B2.2: ${this.state.area2B22} <br/>
                                              Площ-3-L3: ${this.state.area3L3} <br/>
                                              Площ-3-B3.1 ${this.state.area3B31} <br/>
                                              Площ-3-B3.2 ${this.state.area3B32} <br/>
                                              Площ-4-L4: ${this.state.area4L4} <br/>
                                              Площ-4-B4.1 ${this.state.area4B41} <br/>
                                              Площ-4-B3.2 ${this.state.area4B42} <br/>
                                              Мейл: ${this.props.contactDetails[0].email}<br/>
                                              Име: ${this.props.contactDetails[0].name}<br/>
                                              Фирма: ${this.props.contactDetails[0].company}<br/>
                                              Телефон: ${this.props.contactDetails[0].phone}<br/>

                                              Последно Съобщение:
                                            </p>
                                        </td>
                                      </tr>
                                  </table>

                                  <p style="font-size:18px;line-height:24px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                    Съвсем скоро ще отговорим на вашето запитване!<br/>
                                    - HydroPro
                                  </p>

                                </td>
                                <td><img src="https://hydropro.bg/email/pix.gif" width="10" height="1" /></td>
                              </tr>
                            </table>

                            <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                <tr>
                                  <td style="text-align:center;">

                                    <p style="text-align:center;font-size:12px;color:#DFC475;font-family:Georgia,Times,Times New Roman,serif;"><a href="/" target="_blank" style="text-decoration:none;font-size:12px;color:#DFC475;font-weight:bold;font-family:Georgia,Times,Times New Roman,serif;"><img src="https://hydropro.bg/email/hydropro-footer.png" alt="HydroPro" border="0" /></a></p>

                                    <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="10" width="1" border="0" style="display:block;" /></td></tr></table>

                                    <p style="text-align:center;font-size:12px;line-height:21px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                        <span style="text-transform:lowercase;font-family:Georgia,Times,Times New Roman,serif;">Телефон</span>: +359 882 666 777<br/>
                                        <!--span style="text-transform:lowercase">Мейл:</span> скоро<br/ -->
                                        <span style="text-transform:lowercase;font-family:Georgia,Times,Times New Roman,serif;">Адрес:</span> гр. София, бул. "Цариградско шосе" 123, България
                                    </p>

                                    <p style="text-align:center;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">
                                        <a href="https://www.facebook.com/pages/category/Brand/Hydropro-BG-Ltd-1929308104055621/" target="_blank" style="text-decoration: none"><img src="https://hydropro.bg/email/facebook.jpg" alt="Facebook" /></a>
                                        &nbsp;&nbsp;
                                        <a href="https://www.instagram.com/" target="_blank" style="text-decoration: none"><img src="https://hydropro.bg/email/instagram.jpg" alt="Instagram" /></span></a>
                                    </p>

                                    <p style="text-align:center;font-size:12px;color:#201E18;font-family:Georgia,Times,Times New Roman,serif;">2020 © HYDROPRO. Всички права запазени.</p>

                                  </td>
                                </tr>
                              </table>

                              <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="https://hydropro.bg/email/pix.gif" height="40" width="1" border="0" style="display:block;" /></td></tr></table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </body>
            </html>
            `,
          notifySubject: "HydroPro - Получено запитване за продукта"
        })
      })
      .then(result => {
        alert(
          "Успешно изпратихте вашите данни към нас! Ще се свържем с вас при първа възможност."
        );

        //console.log("RESULTS:", result)
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  }

  generatePDFDocument = async () => {
    Font.register({
      family: "Roboto",
      src:
        "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
    });
    const pdfStyles = StyleSheet.create({
      section: { textAlign: 'center', margin: 30 },
      Text: {
        fontFamily: "Roboto"
      }
    });
    const blob = await pdf(
      <Document>
        <Page>
          <Text style={pdfStyles.Text}>Hydropro.bg - Калкулация линейно отводняване</Text>
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.Text}>Вид обект: {this.state.emailfrom}</Text>
            <Text style={pdfStyles.Text}>Наклон на терена</Text>
            <Text>{this.state.slope}</Text>
            <Text style={pdfStyles.Text}>Грапавина</Text>
            <Text>{this.state.roughness}</Text>
            <Text style={pdfStyles.Text}>q [l/s/ha]</Text>
            <Text>{this.state.qlsha}</Text>
            <Text style={pdfStyles.Text}>ѱ [0-1]</Text>
            <Text>{this.state.zeroone}</Text>
            {this.state.areas.map((item, index) => {
              return (
                <View>
                  <Text style={pdfStyles.Text}>Площ {index + 1}</Text>
                  <Text>L: {item.L}</Text>
                  <Text>B1: {item.B1}</Text>
                  <Text>B2: {item.B2}</Text>
                </View>
              );
            })}
            {this.props.calculator.map((item, index) => {
              return (
                <View>
                  <Text style={pdfStyles.Text}>Вид продукт</Text>
                  <Text>HYDROPRO Line - {item.pipe}</Text>
                  <Text style={pdfStyles.Text}>Дължина</Text>
                  <Text>{item.step}</Text>
                </View>
              );
            })}
          </View>
        </Page>
      </Document>
    ).toBlob();

    console.log(blob);

    saveAs(blob, "pageName");
  };
  render() {
    const chunked = [];
    // console.log('details', this.props.contactDetails[0].name, this.props.contactDetails[0].email, this.props.contactDetails[0].phone, this.props.contactDetails[0].company)
    const { loading, slope } = this.state;
    const zeroone = [
      { value: 0.90, label: '0.90' },
      { value: 0.85, label: '0.85' },
      { value: 0.50, label: '0.50' },
      { value: 0.35, label: '0.35' },
      { value: 0.30, label: '0.30' },
      { value: 0.15, label: '0.15' },
      { value: 0.10, label: '0.10' },
    ];
    const customSelectStyles = {
      option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? '#434141' : 'transparent',
        color: state.isSelected ? '#ffffff' : '#ffffff',
        // padding: '5px 10px',
      }),
    }

    return (
      <form
        id="form-contact-us"
        className="form-contact-us"
        onSubmit={this.onSubmit.bind(this)}
      >
        <div className="calculator-liner-drainage">
          <div className="calculator">
            <div className="row">
              <div className="col">
                <div id="s1" className="section section-1">
                  <div className="row">
                    <div className="col">
                      <h4>Изходни данни за обект</h4>
                      <label className="label">Вид обект</label>
                      <div>
                        <input
                          type="text"
                          name="emailfrom"
                          id="emailfrom"
                          className="input"
                          placeholder="Летище, ЖП гара, магистрален път ..."
                          value={this.state.emailfrom}
                          onChange={this.onChange}
                        />
                      </div>
                      <div></div>
                    </div>
                    <div className="col">
                      <h4>Изходни данни за продукта</h4>
                      <label className="label">Налкон на терена</label>
                      <div>
                        <input
                          type="text"
                          name="slope"
                          id="slope"
                          className="input"
                          placeholder="0 m/m"
                          value={this.state.slope}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div id="s3" className="section section-3 section-inactive">
                  <div className="row">
                    <div className="col">
                      <h4>Изходни данни за оразмерителен дъжд</h4>
                      <label className="label">q [l/s/ha]</label>
                      <div>
                        <input
                          type="text"
                          name="qlsha"
                          id="qlsha"
                          className="input"
                          placeholder="0"
                          value={this.state.qlsha}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <h4>Изходни данни за отводняваната територия</h4>
                      <label className="label">ѱ [0-1]</label>
                      <div className="select-box">
                        <CreatableSelect
                          allowCreate={true}
                          multi={true}
                          options={zeroone}
                          className="react-select"
                          styles={customSelectStyles}
                          onChange={(selecedOption) => this.setState({ zeroone: selecedOption.value }, () => console.log(this.state.zeroone))}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div id="s4" className="section section-4 section-inactive">
                  <Area
                    // areaList={this.areaState.bind(this)}
                    onChange={data => {
                      this.setState({
                        areas: data.map(el => {
                          return { L: el.L1, B1: el.B11, B2: el.B12 };
                        })
                      });
                    }}
                  />
                </div>
                <div id="s4" className="section section-4 section-inactive">
                  <div className="row">
                    <div className="col">
                      <label>
                        <input
                          className="checkmark"
                          type="checkbox"
                          onChange={this.onCheck.bind(this)}
                          defaultChecked={this.state.diameters}
                          value="200"
                        />
                        HYDROPRO Line - 200 <span className="checkmark"></span>
                      </label>
                      <br />
                      <label>
                        <input
                          className="checkmark"
                          type="checkbox"
                          onChange={this.onCheck.bind(this)}
                          defaultChecked={this.state.diameters}
                          value="500"
                        />
                        HYDROPRO Line - 500 <span className="checkmark"></span>{" "}
                      </label>
                      <br />
                      <label>
                        <input
                          className="checkmark"
                          type="checkbox"
                          onChange={this.onCheck.bind(this)}
                          defaultChecked={this.state.diameters}
                          value="900"
                        />
                        HYDROPRO Line - 900 <span className="checkmark"></span>{" "}
                      </label>
                      <br />
                    </div>
                    <div className="col">
                      <label>
                        <input
                          className="checkmark"
                          type="checkbox"
                          onChange={this.onCheck.bind(this)}
                          defaultChecked={this.state.diameters}
                          value="300"
                        />
                        HYDROPRO Line - 300 <span className="checkmark"></span>{" "}
                      </label>
                      <br />
                      <label>
                        <input
                          className="checkmark"
                          type="checkbox"
                          onChange={this.onCheck.bind(this)}
                          defaultChecked={this.state.diameters}
                          value="600"
                        />
                        HYDROPRO Line - 600 <span className="checkmark"></span>{" "}
                      </label>
                      <br />
                      <label>
                        <input
                          className="checkmark"
                          type="checkbox"
                          onChange={this.onCheck.bind(this)}
                          defaultChecked={this.state.diameters}
                          value="1000"
                        />
                        HYDROPRO Line - 1000 <span className="checkmark"></span>{" "}
                      </label>
                      <br />
                    </div>
                    <div className="col">
                      <label>
                        <input
                          className="checkmark"
                          type="checkbox"
                          onChange={this.onCheck.bind(this)}
                          defaultChecked={this.state.diameters}
                          value="400"
                        />
                        HYDROPRO Line - 400 <span className="checkmark"></span>{" "}
                      </label>
                      <br />
                      <label>
                        <input
                          className="checkmark"
                          type="checkbox"
                          onChange={this.onCheck.bind(this)}
                          defaultChecked={this.state.diameters}
                          value="800"
                        />
                        HYDROPRO Line - 800 <span className="checkmark"></span>{" "}
                      </label>
                      <br />
                      <label>
                        <input
                          className="checkmark"
                          type="checkbox"
                          onChange={this.onCheck.bind(this)}
                          defaultChecked={this.state.diameters}
                          value="1100"
                        />
                        HYDROPRO Line - 1100 <span className="checkmark"></span>{" "}
                      </label>
                      <br />
                    </div>
                  </div>
                </div>

                <div className="row-bttn bttn-center">
                  <div
                    className="bttn"
                    onClick={() => {
                      const details = {
                        areas: this.state.areas,
                        pipesToCheck: this.state.diameters.map(el => `DN${el}`),
                        slope: this.state.slope.replace(",", "."),
                        gravel: this.state.roughness,
                        q: this.state.qlsha.replace(",", "."),
                        psi: this.state.zeroone
                      };
                      this.props.postData(details);
                    }}
                  >
                    Изчисли
                  </div>
                </div>
              </div>

              <div className="col">
                <div id="result" className="section section-result">
                  <h4>
                    Решение
                    <small>(Предложения, спрямо попълнените данни)</small>
                  </h4>
                  {this.props.calculator.map((item, index) => {
                    // result = 'HYDROPRO Line ' + item.pipe + ' Дължина: ' + item.step
                    return (
                      <div key={index} className="row results-box">
                        <div className="col">
                          <div>Вид продукт</div>
                          <div>HYDROPRO Line - {item.pipe}</div>
                        </div>
                        <div className="col">
                          <div>Дължина</div>
                          <div>{item.step}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {this.props.graph.reduce(function (r, a, i) {
                  if (!i || r[r.length - 1][0].pipe !== a.pipe) {
                    return r.concat([[a]]);
                  }
                  r[r.length - 1].push(a);
                  return r;
                }, [])[0] ? <XYPlot
                  width={600}
                  height={300}
                  getX={d => d.drainage}
                  getY={d => d.step}
                >
                    <HorizontalGridLines />
                    <AreaSeries
                      data={this.props.graph.reduce(function (r, a, i) {
                        if (!i || r[r.length - 1][0].pipe !== a.pipe) {
                          return r.concat([[a]]);
                        }
                        r[r.length - 1].push(a);
                        return r;
                      }, [])[0]} />
                    <AreaSeries
                      data={this.props.graph.reduce(function (r, a, i) {
                        if (!i || r[r.length - 1][0].pipe !== a.pipe) {
                          return r.concat([[a]]);
                        }
                        r[r.length - 1].push(a);
                        return r;
                      }, [])[1]} />
                    <AreaSeries
                      data={this.props.graph.reduce(function (r, a, i) {
                        if (!i || r[r.length - 1][0].pipe !== a.pipe) {
                          return r.concat([[a]]);
                        }
                        r[r.length - 1].push(a);
                        return r;
                      }, [])[2]} />
                    <AreaSeries
                      data={this.props.graph.reduce(function (r, a, i) {
                        if (!i || r[r.length - 1][0].pipe !== a.pipe) {
                          return r.concat([[a]]);
                        }
                        r[r.length - 1].push(a);
                        return r;
                      }, [])[3]} />
                    <AreaSeries
                      data={this.props.graph.reduce(function (r, a, i) {
                        if (!i || r[r.length - 1][0].pipe !== a.pipe) {
                          return r.concat([[a]]);
                        }
                        r[r.length - 1].push(a);
                        return r;
                      }, [])[4]} />
                    <AreaSeries
                      data={this.props.graph.reduce(function (r, a, i) {
                        if (!i || r[r.length - 1][0].pipe !== a.pipe) {
                          return r.concat([[a]]);
                        }
                        r[r.length - 1].push(a);
                        return r;
                      }, [])[5]} />
                    <XAxis />
                    <YAxis />
                  </XYPlot> : ''}

                <div className="ask-bttn">
                  <button style={{ marginBottom: 10 }} type="button" className="bttn" onClick={() => { this.generatePDFDocument("MyDoc") }}>Изтегляне на PDF</button>
                  <button type="submit" className="bttn" disabled={loading}>
                    Направи запитване
                  </button>
                </div>
              </div>
            </div>
            {/* {this.lastMessage}
            <div className='row-bttn'>
              <button type="submit" className='bttn' disabled={loading}>Направи запитване</button>

            </div> */}
          </div>
        </div>
      </form>
    );
  }
}
const mapStateToProps = state => ({
  contactDetails: state.user.contactDetails,
  calculator: state.calculator.data,
  graph: state.calculator.graph
});

const mapDispatchToProps = dispatch => ({
  postData: payload => dispatch(postData(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
